<template>
    <div class="mt-12 mb-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <v-row no-gutters justify="space-between">
            <v-col cols="12" class="d-md-none mb-5">
                <div class="main-title font-weight-bold">
                    <span>{{ props.nome + '&nbsp;' }}</span>
                    <span class="text-primary">{{ props.nome1 }}</span>
                </div>
            </v-col>
            <v-col cols="12" sm="6" xl="4" class="d-flex pr-sm-10 pr-md-16 align-center">
                <div class="position-relative w-100">
                    <IconCard color="purple" class="style position-absolute">
                        <IconSchool width="100" height="85" color="white"></IconSchool>
                    </IconCard>
                    <v-img :src="'https://site-api.iotechpis.com' + props.url" cover aspect-ratio="1" />
                </div>
            </v-col>
            <v-col cols="12" sm="6" xl="8" class="d-flex flex-column ga-8 justify-center mt-3 mt-sm-0">
                <div class="d-none d-md-flex flex-column main-title font-weight-bold">
                    <span>{{ props.nome }}</span>
                    <span class="text-primary">{{ props.nome1 }}</span>
                </div>
                <div class="d-flex flex-column ga-3 normal-text font">
                    <div>{{ props.text }}</div>
                    <div>{{ props.text1 }}</div>
                    <div>{{ props.text2 }}</div>
                </div>
                <div class="w-100 d-flex justify-start">
                    <v-btn class="small-text mt-sm-4" rounded="xl" :to="props.button.isExternal ? '' : props.button.url"
                        :href="props.button.isExternal ? props.button.url : ''"
                        :target="props.button.isExternal ? '_blank' : ''">
                        {{ props.button.title }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import IconCard from '@/components/icons/IconCard.vue';
import IconSchool from '@/components/icons/IconSchool.vue';
import { defineProps } from 'vue';

const props = defineProps(['nome', 'nome1', 'text', 'text1', 'text2', 'button', 'url'])
</script>

<style scoped>
.style {
    top: 50%;
    right: -5%;
    z-index: 1;
}
</style>