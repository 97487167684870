<template>
  <v-app-bar app class="d-flex px-8 px-md-16 py-2 py-md-3 py-xl-4 bg-primary navbar">
    <v-container fluid class="d-flex align-center px-md-10 px-xl-16">
      <div class="pointer" @click="home">
        <v-img src="../../assets/img/logo/white-text-logo.png" rounded="0"
          :width="vuetify.display.height.value > 1000 ? '11vh' : '15vh'">
        </v-img>
      </div>
      <div class="d-none d-lg-flex ga-0 ga-xl-3 align-center ml-4 ml-xl-8">
        <div class="d-flex" v-for="item in items" :key="item.id">
          <v-hover v-slot="{ isHovering, props }" close-delay="200">
            <v-btn v-if="item.options" variant="plain" v-bind="props" color="white" class="small-text rounded-b-0"
              :class="[isHovering ? 'bg-third' : 'bg-primary']" :id="'menu-activator' + item.nome.replace(/ +/g, '')">
              {{ item.nome }}
            </v-btn>
            <v-btn v-else variant="plain" v-bind="props" color="white" class="small-text " :to="item.to"
              :class="[isHovering ? 'bg-third' : 'bg-primary']">
              {{ item.nome }}
            </v-btn>
            <v-menu v-if="item.options" open-on-hover open-on-click v-bind="props" open-delay="0" close-delay="100"
              :activator="'#menu-activator' + item.nome.replace(/ +/g, '')">
              <v-list class="bg-third  rounded-b-lg rounded-te-lg rounded-t-0 pa-0 elevation-0">
                <v-list-item class="" :to="item.to + option.to" v-for=" option in item.options" :key="option.nome"
                  :value="option.nome">
                  <v-list-item-title class="small-text ">{{ option.nome }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-hover>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div>
        <div class="d-none d-lg-flex ga-2 ga-xl-4 align-center">
          <v-btn v-if="vuetify.display.width.value < 1365" icon="mdi-email" size="small"
            class="rounded-xl small-text  border-sm text-white border-opacity-100 border-background" color="transparent"
            variant="flat" href="mailto:info@iotech.pt">
          </v-btn>
          <v-btn v-else prepend-icon="mdi-email"
            class="rounded-xl small-text  border-sm text-white border-opacity-100 border-background" color="transparent"
            variant="flat" href="mailto:info@iotech.pt">
            Email
          </v-btn>
          <v-btn v-if="vuetify.display.width.value < 1365" icon="mdi-phone" size="small" class="rounded-xl small-text "
            variant="flat" color="#6D9DF5" href="tel:+351302002958">
          </v-btn>
          <v-btn v-else prepend-icon="mdi-phone" class="rounded-xl small-text " variant="flat" color="#6D9DF5"
            href="tel:+351302002958">
            Ligar
          </v-btn>
          <TheSettings class="ml-3 ml-xl-4"></TheSettings>
        </div>
        <v-app-bar-nav-icon @click="drawer" variant="plain" color="white" class="d-lg-none mr-n7"></v-app-bar-nav-icon>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script setup>
import vuetify from "@/plugins/vuetify";
import { computed, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TheSettings from "./TheSettings.vue";

const store = useStore();
const router = useRouter()
const emits = defineEmits(['drawer'])


function drawer() {
  emits('drawer')
}

const items = computed(() => {
  return store.getters.getItems;
});


function home() {
  router.push('/')
}


</script>

<style scoped>
/* Navbar gradient */
.navbar {
  position: relative;
  overflow: hidden;
}

.navbar::before,
.navbar::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.navbar::before {
  top: 10%;
  left: -5%;
  width: 180px;
  height: 180px;
  background: radial-gradient(circle, rgba(108, 153, 205, 0.902) 0%, rgba(91, 222, 255, 0) 65%);
  opacity: 0.50;

}

.navbar::after {
  top: 2%;
  right: 8%;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(108, 153, 205, 0.902) 0%, rgba(91, 222, 255, 0) 65%);
  opacity: 0.65;
}

.v-btn {
  transition: color 0.5s ease;
}

.v-btn--active {
  font-weight: 900;
}


.v-list-item--active {
  font-weight: 900;
}

.pointer {
  cursor: pointer;
}
</style>
