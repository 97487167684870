<template>
    <div class="mt-12 mb-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <v-row no-gutters>
            <v-col cols="12" sm="6" md="7" class="pr-5 pr-md-8">
                <div class="main-title font-weight-bold">
                    <span>
                        {{ props.nome + '&nbsp;' }}
                    </span>
                    <span class="text-primary">
                        {{ props.nome1 }}
                    </span>
                </div>
                <div class="mt-6 normal-text">
                    <span>
                        {{ props.text }}
                    </span>
                    <div class="mt-6 d-flex flex-column ga-3">
                        <div>
                            <v-icon :icon="'mdi-check-circle'" color="green"></v-icon>
                            {{ props.passos[0].text }}
                        </div>
                        <div>
                            <v-icon :icon="'mdi-check-circle'" color="green"></v-icon>
                            {{ props.passos[1].text }}
                        </div>
                    </div>
                </div>
                <div class="mt-6 d-flex d-sm-none d-md-flex flex-column ga-1 normal-text">
                    <span class=" font-weight-bold mb-2">{{ props.listaTitulo.text }}</span>
                    <div v-for="(requisito, index) in props.requisitos" :key="index">
                        <span class="text-purple font-weight-bold">&gt;</span>
                        <span>&nbsp;{{ requisito.text }}</span>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="5" class="position-relative">
                <div class="position-relative">
                    <v-img :src="url" rounded="xl" cover class="mt-6 mt-sm-0">
                    </v-img>
                    <NameTag v-for="(tag, index) in props.hashtag" v-show="index != 3 && vuetify.display.smAndDown"
                        :key="index" :color="tag.color" class="position-absolute" :class="'style' + index"
                        :hashtag="tag.hashtag">{{ tag.text }}
                    </NameTag>
                </div>
            </v-col>
            <v-col cols="12" class="d-none d-sm-block d-md-none normal-text">
                <div class="mt-10 mt-sm-5 mt-md-10 d-flex flex-column ga-1">
                    <span class="font font-weight-bold mb-2">{{ props.listaTitulo.text }}</span>
                    <div v-for="(requisito, index) in props.requisitos" :key="index">
                        <span class="text-purple font-weight-bold font">&gt;</span>
                        <span>{{ '&nbsp;' + requisito.text }}</span>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { defineProps, ref } from 'vue';
import NameTag from '../NameTag.vue';


const props = defineProps(['nome', 'nome1', 'text', 'passos', 'listaTitulo', 'requisitos', 'hashtag']);
const url = ref('https://img.freepik.com/fotos-premium/amigos-estudando-juntos-numa-biblioteca_1280275-160778.jpg?w=826')
</script>

<style scoped>
.style0 {
    bottom: 20%;
    left: -5%;
}

.style1 {
    bottom: 5%;
    right: 15%;
}

.style2 {
    bottom: 50%;
    right: 2%;
}

.style3 {
    top: 15%;
    left: 10%;
}

.style4 {
    top: 6%;
    right: -5%;
}
</style>