<template>
  <div class="mt-6 mt-md-10 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
    <v-row no-gutters class="text-center text-md-left">
      <v-col cols="12" md="6" lg="4" class="justify-center align-center position-relative pr-md-4">
        <div class="d-flex flex-column d-md-none">
          <BaseIdentifier :nome="props.nome" :color="props.color" :icon="props.icon"></BaseIdentifier>
          <span class="main-title mt-8 font-weight-bold">{{ props.titulo }}</span>
          <span class="main-title mb-4 font-weight-bold">
            <span class="text-secondary">{{ props.titulo1 }}</span>
          </span>
        </div>
        <v-row no-gutters align-content="stretch" class="d-flex flex-column justify-sm-center ga-4 position-relative">
          <IconCard class="position-absolute position1">
            <IconChart :color="themeColors[props.color]" width="40" height="40"></IconChart>
          </IconCard>
          <v-col cols="12">
            <v-img eager :src="'https://site-api.iotechpis.com' + props.urls[0].url" class="rounded-xl"></v-img>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-8">
          <IconCard class="position-absolute position">
            <IconAI :color="themeColors[props.color]" height="30" width="30"></IconAI>
          </IconCard>
          <v-col md="8" class="z-index">
            <v-img :eager="true" :src="'https://site-api.iotechpis.com' + props.urls[1].url" class="rounded-xl"></v-img>
          </v-col>
          <v-col md="8" class="overlap-column">
            <v-img :eager="true" :src="'https://site-api.iotechpis.com' + props.urls[2].url"
              class="rounded-xl opacity-20">
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" lg="8" class="d-flex flex-column pl-md-4">
        <BaseIdentifier class="d-none d-md-flex" :nome="props.nome" :color="props.color" :icon="props.icon">
        </BaseIdentifier>
        <div>
          <div class="d-none d-md-flex mt-6 flex-column main-title font-weight-bold">
            <span>
              {{ props.titulo }}
            </span>
            <span class="text-secondary">
              {{ props.titulo1 }}
            </span>
          </div>
          <v-row no-gutters class="d-flex ga-2 mt-5 description-text">
            <div class="w-100 font-weight-bold normal-text mb-1">
              {{ props.descricao }}
            </div>
            <div class="mt-1 mb-1 w-100">
              {{ props.descricao1 }}
            </div>
            <div class="d-flex flex-column ga-2 w-100">
              <div class="py-1 " v-for="(topic, index) in topicos" :key="index">
                <span :class="['text-' + props.color, 'font-weight-bold']"> <v-icon :icon="'mdi-' + 'chevron-right'" />
                </span>{{ topic.text }}
              </div>
            </div>
            <div class="mt-1 w-100 ">
              {{ props.descricao2 }}
            </div>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';

import IconAI from '@/components/icons/IconAI.vue';
import IconCard from '@/components/icons/IconCard.vue';
import IconChart from '@/components/icons/IconChart.vue';
import { useTheme } from 'vuetify';
import BaseIdentifier from '../BaseIdentifier.vue';

const theme = useTheme();
const themeColors = theme.current.value.colors;

const props = defineProps(['nome', 'color', 'topics', 'titulo', 'titulo1', 'descricao', 'descricao1', 'descricao2', 'urls', 'icon'])

const topicos = ref([])
function getTopics() {
  props.topics.forEach(element => {
    if (props.topics.indexOf(element) >= 2 && props.topics.indexOf(element) < 6) {
      topicos.value.push(element)
    }
  });
}
getTopics()
</script>

<style scoped>
.position {
  bottom: 10%;
  left: 58%;
  z-index: 2;
}

.position1 {
  bottom: -9%;
  left: 75%;
  z-index: 1;
}

.z-index {
  z-index: 1;
}

.overlap-column {
  position: relative;
  margin-left: -34%;
}
</style>
