<template>
  <div>
    <v-btn class="size border-sm elevation-4" size="large" icon="mdi-arrow-up" @click="scrollTo"></v-btn>
  </div>
</template>

<script setup>


function scrollTo() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

</script>

<style scoped>
.size:hover {
  color: rgba(143, 143, 143, 0.808) !important;
}

.style {
  width: min-content
}
</style>
