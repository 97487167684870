<template>
    <div class="mt-12 mb-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <div class="d-md-flex w-100 justify-center main-title font-weight-bold mb-5">
            <span>
                {{ props.nome + '&nbsp;' }}
            </span>
            <span class="text-primary">
                {{ props.nome1 }}
            </span>
        </div>
        <v-row no-gutters class="d-grid mx-n3">
            <v-col cols="12" sm="6" :md="props.colunas" v-for="(area, index) in areas" :key="index" class="pa-3"
                :class="vuetify.display.mobile.value ? 'flip-card-sm' : 'flip-card'" v-model="flip[index]"
                @click="flipper(index)">
                <div class="flip-card-inner" :class="flip[index] ? 'flip-mobile' : ''">
                    <v-card class="front d-flex pa-2 w-100 align-center text-sm-h5 text-xl-h4 rounded-xl pa-xl-5"
                        :class="[index % 2 == 0 && props.colunas == 4 ? 'background1' : props.colunas === 4 ? 'background' : 'background2', 'bg-' + props.color]">
                        <div class="pa-4  description-text font-weight-bold" :class="[index >= 3 ? 'w-50' : '']">
                            {{ area.front }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-icon :icon="'mdi-' + area.icon" size="x-large" class="ma-4 opacity-60" />
                    </v-card>
                    <v-card class=" back d-flex pa-2 w-100 align-center rounded-xl pa-xl-5"
                        :class="[index % 2 == 0 && props.colunas == 4 ? 'background1' : props.colunas === 4 ? 'background' : 'background2', 'bg-' + props.color]">
                        <div class="pa-4  description-text font-weight-bold">
                            {{ area.back }} (Back)
                        </div>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>

import vuetify from '@/plugins/vuetify';
import { defineProps, ref } from 'vue';


const props = defineProps(['nome', 'nome1', 'colunas', 'color', 'areas'])

const flip = ref([])

function flipper(index) {
    if (vuetify.display.mobile.value) {
        flip.value[index] = !flip.value[index]
    }
}
</script>

<style scoped>
.background {
    background: linear-gradient(to right, #e0efff42, #3476b8);
}


.background1 {
    background: linear-gradient(to left, #e0efff42, #3476b8)
}


.background2 {
    background: linear-gradient(to right, #e0efff42, #9b27b0c7);
}


.flip-card {
    height: 19vh;
    width: 100%;
    perspective: 2000px;
}

.flip-card-sm {
    height: 16.5vh;
    width: 100%;
    perspective: 2000px;
}


.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-mobile {
    transform: rotateY(180deg);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.front,
.back {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.back {
    transform: rotateY(180deg);
}
</style>