import { createApp } from "vue";

import vue3TouchEvents from "vue3-touch-events";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./plugins/webfontloader"; // Import the webfontloader configuration
import router from "./router";
import store from "./store";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify, {});
app.use(vue3TouchEvents);

router.isReady().then(() => {
    app.mount("#app");
});
