<template>
    <div class="w-100 bg-blueWhite" :class="[$route.name === 'ioAcademy' ? 'gradient2' : 'gradient']">
        <div class="mt-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16 text-text font-weight-bold description-text">
            <v-form @submit.prevent="submit" ref="form" validate-on="blur">
                <v-row no-gutters class="d-flex flex-column main-title font-weight-bold pb-8 pt-16">
                    <v-col cols="12" md="7">
                        {{ props.nome }}
                    </v-col>
                    <v-col cols="12" md="7" class="text-primary">
                        {{ props.nome1 }}
                    </v-col>
                </v-row>
                <v-row no-gutters class="d-flex mx-n4">
                    <v-col cols="12" sm="6" class="pa-4 py-0">
                        <label>{{ props.form.inputTitle[0].text }}</label>
                        <v-text-field type="text" v-model.trim="nome" :rules="nome_rules" clearable
                            :label="props.form.placeholders[0].text" variant="solo" flat rounded="xl"
                            class="mt-2 opacity-60" required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-4 py-0">
                        <label>{{ props.form.inputTitle[1].text }}</label>
                        <v-text-field type="text" v-model.trim="email" :rules="email_rules" clearable
                            :label="props.form.placeholders[1].text" variant="solo" rounded="xl" flat
                            class="mt-2 opacity-60" required>
                        </v-text-field>
                    </v-col>
                    <v-col v-if="$route.name === 'ioAcademy'" cols="12" sm="6" class="pa-4 py-0">
                        <label>{{ props.form.inputTitle[2].text }}</label>
                        <v-text-field v-model.trim="curso" :rules="curso_rules" clearable
                            :label="props.form.placeholders[2].text" variant="solo" flat rounded="xl"
                            class="mt-2 opacity-60" required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-4 py-0">
                        <label>{{ props.form.inputTitle[4] ?
                            props.form.inputTitle[3].text : props.form.inputTitle[2].text }}</label>
                        <v-autocomplete v-model.trim="area" :rules="area_rules"
                            :label="props.form.placeholders[8] ? props.form.placeholders[3].text : props.form.placeholders[2].text"
                            :items="items" variant="solo" flat rounded="xl" clearable open-on-clear
                            class="mt-2 opacity-60" required>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="px-4">
                        <label>{{ props.form.inputTitle[4] ? props.form.inputTitle[4].text :
                            props.form.inputTitle[3].text }}</label>
                        <v-file-input v-model="file" :rules="file_rules" validate-on="submit" show-size prepend-icon=""
                            variant="solo" flat accept=".pdf" rounded="xl" single-line class="d-grid mt-2 opacity-60">
                            <template v-slot:prepend-inner>
                                <v-container class="my-16 py-10 px-0 mx-0"></v-container>
                            </template>
                            <template v-slot:label>
                                <div class="d-flex w-100 flex-column align-center text-center text-sm-body-1 font">
                                    <v-icon icon="mdi-file-send" size="x-large"
                                        :color="route.name === 'careers' ? 'primary' : 'purple'"
                                        class="opacity-80 text-h2"></v-icon>
                                    <div class="text-wrap">
                                        {{ props.form.placeholders[8] ? props.form.placeholders[4].text :
                                            props.form.placeholders[3].text }}
                                        <span class="text-primary">{{ props.form.placeholders[8] ?
                                            props.form.placeholders[5].text : props.form.placeholders[4].text }}</span>
                                        {{ props.form.placeholders[8] ? props.form.placeholders[6].text :
                                            props.form.placeholders[5].text }}
                                    </div>
                                    <div class="text-wrap">
                                        {{ props.form.placeholders[8] ? props.form.placeholders[7].text :
                                            props.form.placeholders[6].text }}
                                    </div>
                                    <div class="text-wrap">
                                        {{ props.form.placeholders[8] ?
                                            props.form.placeholders[8].text : props.form.placeholders[7].text }}
                                    </div>
                                </div>
                            </template>
                            <template v-slot:selection="{ fileNames }">
                                <template v-for="fileName in fileNames" :key="fileName">
                                    <v-container
                                        class="my-14 w-100 h-100 d-flex align-center justify-center text-center">
                                        <div class="d-flex justify-center align-center w-100 h-100">
                                            <v-chip class="pa-4" rounded="xl" color="primary" label>
                                                {{ fileName }}
                                            </v-chip>
                                        </div>
                                    </v-container>
                                </template>
                            </template>
                        </v-file-input>
                    </v-col>
                </v-row>
                <div class="w-100 d-flex justify-center justify-sm-end pt-5 pb-16">
                    <v-btn type="submit" rounded="xl" class="px-6 small-text">{{ props.form.button.title }}</v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

const props = defineProps(['nome', 'nome1', 'form'])
const emits = defineEmits(['open'])

onMounted(width)
onBeforeRouteLeave(beforeLeave)

const route = useRoute()
function width() {
    const labelElements = document.querySelectorAll('.v-field-label');
    if (route.name === 'careers') {
        labelElements[6].classList.add('w-100')
    }
    else labelElements[8].classList.add('w-100')
}


function beforeLeave(to, from, next) {
    if (nome.value != null || email.value != null || area.value != null || file.value.length != 0 || curso.value != null) {
        const answer = window.confirm('Os dados introduzidos no formulário serão perdidos ao sair da página! Pretende continuar ?');
        if (answer) {
            next(); // Allow the navigation
        } else {
            next(false); // Block the navigation
        }
    }
    else {
        next();
    }
}

const items = computed(() => {
    let areas = []
    props.form.areas.forEach(element => {
        areas.push(element.front)
    });
    return areas
})


//formulário regras e verificação
let form = ref(null)

const nome = ref(null);
const fullNameRegex = new RegExp("^[A-Z][a-zA-Z]*(?:[-' ][A-Z][a-zA-Z]*)+$");

const nome_rules = [
    value => {
        if (fullNameRegex.test(value)) {
            return true
        }
        else return 'Requisitos mínimos: primeiro e último nome (ex:João Sargento).'
    }
]

const email = ref(null);
const emailRegex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");

const email_rules = [
    value => {
        if (emailRegex.test(value)) {
            return true
        }
        else return 'O email deve ser valido!'
    }
]


const curso = ref(null);
const cursoRegex = new RegExp(/^^[a-zA-ZáéíóúñÑ]+(?:['-][a-zA-ZáéíóúñÑ]+)?(?: [a-zA-ZáéíóúñÑ]+(?:['-][a-zA-ZáéíóúñÑ]+)?)+$/);

const curso_rules = [
    value => {
        if (cursoRegex.test(value)) {
            return true
        }
        else return 'Não são permitidos caracteres especiais, apenas hifens e apóstrofes. Nem números ou espaços consecutivos!'
    }
]


const area = ref(null);

const area_rules = [
    value => {
        if (items.value.includes(value)) {
            return true
        }
        else return 'Deve ser uma das areas possíveis de escolher!'
    }
]

const file = ref([])
const file_rules = [
    value => {
        if (value.length === 0) {
            return false;
        }

        const file = value[0];

        const isFileSizeValid = file.size < 10000000;
        if (!isFileSizeValid) {
            return 'Currículo não deve exceder os 10MB!';
        }

        const isFilePdf = file.type === "application/pdf";
        if (!isFilePdf) {
            return 'O arquivo deve ser um PDF!';
        }

        return true;
    },
];


async function submit() {
    const { valid } = await form.value.validate();
    if (valid) {
        const reader = new FileReader();
        reader.onload = (event) => {
            const fileData = event.target.result; // Base64-encoded file content

            let data = {}
            if (route.name === 'ioAcademy') {
                data = {
                    nome: nome.value,
                    email: email.value,
                    curso: curso.value,
                    area: area.value,
                    file: fileData,
                };
            } else if (route.name === 'careers') {
                data = {
                    nome: nome.value,
                    email: email.value,
                    area: area.value,
                    file: fileData,
                };
            }

            const jsonData = JSON.stringify(data);
            console.log(jsonData);

            emits('open', true)
            form.value.reset()
        };

        reader.readAsDataURL(file.value[0]);
    }
    else {
        emits('open', false)
        alert('erro!')
    }
}
</script>

<style scoped>
.gradient {
    background: linear-gradient(180deg, #27272708, #00000009);
}

.gradient2 {
    background: linear-gradient(10deg, #27272708, #E6DCEA, #00000000);
}

.z-index {
    z-index: 1000;
}
</style>