<template>
    <div class="mt-12 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <div class="w-100 main-title font-weight-bold" :class="[vuetify.display.xs.value ? 'd-flex flex-column' : '']">
            <span>
                {{ props.nome + '&nbsp;' }}
            </span>
            <span class="text-primary">
                {{ props.nome1 }}
            </span>
        </div>
        <v-row no-gutters class="mt-5 mx-n6">
            <v-col cols="12" sm="6" v-for="(item, index) in props.data" :key="index" class="pa-6">
                <v-img rounded="xl" :src="item.url">
                    <NameTag :color="item.tagColor" class="position-absolute ma-4 bottom-0 left-0">
                        {{ item.tag }}
                    </NameTag>
                </v-img>
                <div class="normal-text font-weight-bold mt-3">
                    {{ item.title }}
                </div>
                <div class="description-text">
                    {{ item.description }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { defineProps } from 'vue';
import NameTag from '../NameTag.vue';

const props = defineProps(['nome', 'nome1', 'data'])
</script>

<style scoped></style>