// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
    defaults: {
        global: {
            ripple: false,
        },
        VBtn: {
            variant: "flat",
            color: "#2D5FA9",
        },
        VTextField: {
            variant: "outlined",
        },
        VSelect: {
            variant: "outlined",
        },
        VImg: {
            rounded: "xl",
        },
    },
    theme: {
        themes: {
            iotheme: {
                dark: false,
                colors: {
                    primary: "#2D5FA9",
                    secondary: "#00477C",
                    third: "#1B488B",
                    text: "#333333",
                    background: "#FFFFFF",
                    blue: "#00A8C9",
                    blueWhite: "#EBF2FB",
                    button: "#6D9DF5",
                    light: "#5879AA",
                    red: "#B52B4D",
                    lightRed: "#F3DFE3",
                    yellow: "#E5A437",
                    lightblue: "#62ABC7",
                    green: "#5E9B4E",
                    darkGreen: "#43A047",
                    cookies: "#F1F6F4",
                    lightGrey: "#F3F0F4",
                },
            },
        },
    },
});
