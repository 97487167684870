import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default function useLoad(
    getCounter,
    getData,
    page = null,
    pageId = null
) {
    const route = useRoute();
    const store = useStore();

    let count = 0;
    let interval;
    let intervalMultiPage;
    const routeName = ref(route.name);
    let timer;
    let timerMultiPage;

    const load = async () => {
        routeName.value = route.name;
        count++;
        if (
            !store.getters[getCounter] &&
            count < 5 &&
            route.name === routeName.value
        ) {
            await store.dispatch(getData);
        } else {
            clearInterval(interval);
        }
    };
    timer = () => {
        if (!store.getters[getCounter]) {
            interval = setInterval(() => {
                load();
            }, 60000);
        }
    };

    const loadMultiPage = async () => {
        count++;
        console.log(
            "🚀 ~ loadMultiPage ~ route.name :",
            route.name === routeName.value
        );

        if (
            !store.getters[getCounter][page.value] &&
            count < 5 &&
            route.name === routeName.value
        ) {
            await store.dispatch(getData, pageId.value);
        } else {
            console.log(
                "🚀 ~ loadMultiPage ~ timerMultiPage:",
                intervalMultiPage
            );
            clearInterval(intervalMultiPage);
        }
    };

    timerMultiPage = () => {
        if (!store.getters[getCounter][page.value]) {
            intervalMultiPage = setInterval(() => {
                loadMultiPage();
            }, 60000);
        }
    };

    return [load, timer, loadMultiPage, timerMultiPage];
}
