<template>
    <div class="bg-blueWhite mt-12 pb-12 pt-6">
        <div class="d-flex flex-column ga-4 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
            <div class="w-100 text-center font-weight-bold text-text main-title">
                {{ props.nome + '&nbsp;' }}
                <span class="text-primary">
                    {{ props.nome1 }}
                </span>
            </div>
            <div>
                <v-row class="d-flex justify-center align-center">
                    <v-col class="d-none d-md-flex" v-for="(cliente, index) in clientes" :key="index">
                        <v-img :src="cliente.logo ? 'https://site-api.iotechpis.com' + cliente.logo.url : ''"
                            aspect-ratio="1" width="10vw" rounded="0"></v-img>

                    </v-col>
                    <v-col class="d-md-none" cols="5" v-for="(cliente, index) in clientes" :key="index">
                        <v-img :src="cliente.logo ? 'https://site-api.iotechpis.com' + cliente.logo.url : ''"
                            rounded="0"></v-img>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['nome', 'nome1', 'clientes'])
</script>

<style scoped></style>