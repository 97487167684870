<template>
    <div class="mt-12 mb-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16" id="positions">
        <div class="d-md-flex w-100 main-title font-weight-bold mb-5">
            <div>
                {{ props.nome + '&nbsp;' }}
            </div>
            <div class="text-primary">
                {{ props.nome1 }}
            </div>
        </div>
        <div class="d-flex w-100 normal-text font-weight-medium">
            <span>{{ texto }}</span>
        </div>
        <v-row no-gutters class="mt-8 d-flex justify-center">
            <v-col cols="12" sm="8" md="7" class="d-flex ga-3 ga-sm-4 ga-md-3"
                :class="vuetify.display.smAndDown.value ? 'flex-column' : ''">
                <v-btn v-for="(opcao, index) in selecao" :key="index" rounded base-color="white"
                    class="small-text ma-sm-0" variant="outlined" @click="setFiltro(opcao.description)"
                    :class="[filtro === opcao.description ? 'bg-primary' : '']">
                    {{ opcao.title }}
                </v-btn>
            </v-col>
            <v-spacer class="d-none d-md-block"></v-spacer>
            <v-col cols="12" sm="8" md="3" class="d-flex justify-end mt-4 mt-md-0"
                :class="vuetify.display.mobile.value ? 'flex-column' : ''">
                <v-btn rounded color="purple" class="small-text" @click="setFiltro('ES')"
                    :class="[filtro === 'ES' ? 'bg-purple-darken-3 ' : '']">
                    Oportunidades para estudantes
                </v-btn>
            </v-col>
        </v-row>
        <div class="d-flex flex-column w-100 mt-5 position-relative ma-0 height">
            <TransitionGroup name="list">
                <div v-show="number === props.jobs.length" :key="props.jobs.length + 1"
                    class="w-100 text-center normal-text font-weight-medium py-16">
                    Sem ofertas
                </div>
                <JobOpening v-for="(job, index) in props.jobs" :job="job" :key="index"
                    v-show="filtro ? filtro === job.tags : true"
                    :class="{ notVisible: filtro ? filtro != job.tags : false }" />
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';

import vuetify from '@/plugins/vuetify';
import JobOpening from './JobOpening.vue';

const props = defineProps(['nome', 'nome1', 'texto', 'selecao', 'jobs'])


const number = ref(0)
const filtro = ref(null)
function setFiltro(value) {
    setTimeout(() => {
        count()
    }, 1);
    if (value) {
        filtro.value = value
    }
    else filtro.value = null
}

count()
function count() {
    number.value = document.getElementsByClassName('notVisible').length
}
</script>

<style scoped>
.height {
    transition: all 0.6s ease;
}

.list-move {
    transition: all 0.2s ease;
}

.list-enter-active {
    animation: slide 0.65s;
    animation-timing-function: ease-in-out;
}

.list-leave-active {
    animation: slide 0.2s reverse;
    animation-timing-function: ease-in-out;
}

.list-leave-active {
    position: absolute;
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
</style>