<template>
    <div v-if="loaded">
        <AcademyIntro :nome="intro.paragraph[0].header" :nome1="intro.paragraph[0].blueHeader"
            :texto="intro.paragraph[0].content[0].text" :cards="cards" :header="intro.header" :color="intro.color"
            :icon="intro.icon" />
        <AcademyDescription :nome="intro.paragraph[1].header" :nome1="intro.paragraph[1].blueHeader"
            :text="intro.paragraph[1].content[0].text" :text1="intro.paragraph[1].content[1].text"
            :text2="intro.paragraph[1].content[2].text" :button="intro.button" :url="intro.image.url" />
        <AcademyBenefits :nome="offers.header" :nome1="offers.blueHeader" :text="offers.description"
            :offers="offers.trainingCards" />
        <AreasIO :nome="areas.header" :nome1="areas.blueHeader" :colunas="6" :color="areas.color" :areas="areas.areas">
        </AreasIO>
        <AcademyAdmission :nome="requisitos.paragraph.header" :nome1="requisitos.paragraph.blueHeader"
            :text="requisitos.paragraph.content[0].text" :passos="passos" :listaTitulo="ListaTitulo"
            :requisitos="requisitosLista" :hashtag="requisitos.hashtags"></AcademyAdmission>
        <OurProjects :nome="comments.header" :nome1="comments.blueHeader" :texto1="comments.description"
            :items="comments.student_feedbacks" bg="grey-lighten-4" :expand="false">
        </OurProjects>
        <FormsIO :nome="form.header" :nome1="form.blueHeader" :form="form" id="forms" @open="openSnackBar"></FormsIO>
        <SnackBar :snackbar="snackbar" :color="color" :mensagem="snackbarmessage" :mensagem1="snackbarmessage1"
            @close="closeSnackBar" />
    </div>
    <TheSpinner v-else></TheSpinner>
</template>

<script setup>
import SnackBar from '@/components/layout/SnackBar.vue';
import TheSpinner from '@/components/layout/TheSpinner.vue';
import AcademyAdmission from '@/components/ui/carreiras/AcademyAdmission.vue';
import AcademyBenefits from '@/components/ui/carreiras/AcademyBenefits.vue';
import AcademyDescription from '@/components/ui/carreiras/AcademyDescription.vue';
import AcademyIntro from '@/components/ui/carreiras/AcademyIntro.vue';
import AreasIO from '@/components/ui/carreiras/AreasIO.vue';
import FormsIO from '@/components/ui/carreiras/FormsIO.vue';
import OurProjects from '@/components/ui/OurProjects.vue';
import vuetify from '@/plugins/vuetify';

import useLoad from '@/hooks/load';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore()
const page = ref(0)
const pageId = ref('ioacademy')
const [, , loadMultiPage, timerMultiPage] = useLoad('careers/getCounter', 'careers/getData', page, pageId)

loadMultiPage().then(timerMultiPage)

const loaded = computed(() => {
    return store.getters['careers/getCounter'][1]
})

watch(() => loaded.value, () => {
    if (!loaded.value) {
        loadMultiPage().then(timerMultiPage)
    }
})

const cards = computed(() => {
    return store.getters['careers/getFloatingCards']
})
const intro = computed(() => {
    return store.getters['careers/getIntroA']
})
const offers = computed(() => {
    return store.getters['careers/getOferta']
})
const areas = computed(() => {
    return store.getters['careers/getAreas'][1]
})
const requisitos = computed(() => {
    return store.getters['careers/getRequisitos']
})
const passos = computed(() => {
    return [requisitos.value.paragraph.content[1], requisitos.value.paragraph.content[2]]
})
const ListaTitulo = computed(() => {
    return requisitos.value.paragraph.content[3]
})
const requisitosLista = computed(() => {
    return [
        requisitos.value.paragraph.content[4],
        requisitos.value.paragraph.content[5],
        requisitos.value.paragraph.content[6],
        requisitos.value.paragraph.content[7],
    ]
})
const comments = computed(() => {
    return store.getters['careers/getComments']
})
const form = computed(() => {
    return store.getters['careers/getForm']
})


const snackbar = ref(false)
const snackbarmessage = ref(null)
const snackbarmessage1 = ref(null)
const color = ref(null)
function closeSnackBar() {
    snackbar.value = !snackbar.value
}
function openSnackBar(value) {
    if (value) {
        snackbarmessage.value = !vuetify.display.mobile.value ? 'Candidatura submetida com sucesso. Ire-mos entrar em contacto em breve!' : 'Enviada!'
        snackbarmessage1.value = 'OK!'
        color.value = '#5E9B4E'
        snackbar.value = true
    }
    else {
        snackbarmessage.value = !vuetify.display.mobile.value ? 'Erro de envio! Algo de inesperado ocorreu!' : 'Erro!'
        snackbarmessage1.value = 'Tente novamente!'
        color.value = '#B52B4D'
        snackbar.value = true
    }
}


// const offers = ref([
//     {
//         icon: 'bulletin-board',
//         titulo: 'Training',
//         items: [
//             'continuos',
//             'online',
//             'Up-to-date'
//         ],
//         color: 'green'
//     },
//     {
//         icon: 'bulletin-board',
//         titulo: 'Training',
//         items: [
//             'continuos',
//             'online',
//             'Up-to-date'
//         ],
//         color: 'green'
//     },
//     {
//         icon: 'bulletin-board',
//         titulo: 'Training',
//         items: [
//             'continuos',
//             'online',
//             'Up-to-date'
//         ],
//         color: 'green'
//     },
//     {
//         icon: 'bulletin-board',
//         titulo: 'Training',
//         items: [
//             'continuos',
//             'online',
//             'Up-to-date'
//         ],
//         color: 'green'
//     }
// ])


// const comments = [
//     {
//         type: 'Desevolvimento Web',
//         comment: 'I joined ioAcademy because it is an opportunity to gain knowledge, develop soft skills and apply them in a business environment.',
//         subtitulo: "José Vieira",
//         titulo: "Estudante",
//     },
//     {
//         type: 'Data Science',
//         comment: 'I joined ioAcademy to learn more and improve my academic and entrepreneurial skills.',
//         subtitulo: "Beatriz Lobo",
//         titulo: "Estudante",
//     },
//     {
//         type: 'Desevolvimento Web',
//         comment: 'I joined ioAcademy because it is an opportunity to gain knowledge, develop soft skills and apply them in a business environment.',
//         subtitulo: "Paulo Fernandes",
//         titulo: "Estudante",
//     },
//     {
//         type: 'Desevolvimento Web',
//         comment: 'I joined ioAcademy because it is an opportunity to gain knowledge, develop soft skills and apply them in a business environment.',
//         subtitulo: "Joana Castro",
//         titulo: "Estudante",
//     },
//     {
//         type: 'Desevolvimento Web',
//         comment: 'I joined ioAcademy because it is an opportunity to gain knowledge, develop soft skills and apply them in a business environment.',
//         subtitulo: "Paulo Fernandes",
//         titulo: "Estudante",
//     },
// ]
</script>

<style scoped></style>