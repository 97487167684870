<template>
    <div class="mt-10 mb-14 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16 pb-md-16">
        <div class="w-100 d-flex justify-center">
            <BaseIdentifier :nome="'A nossa identidade'" :color="'primary'" :icon="'chevron'"></BaseIdentifier>
        </div>
        <div class="w-100 text-center main-title font-weight-bold mt-8">
            <span>
                {{ props.nome + '&nbsp;' }}
            </span>
            <span class="text-primary">
                {{ props.nome1 }}
            </span>
        </div>
        <div class="w-100 d-flex justify-center text-center mt-3 pb-12 pb-xl-14">
            <span class="mt-3 normal-text w-xl-75">
                {{ props.text }}
            </span>
        </div>
    </div>
    <IconBracket class="position-absolute style" :color="themeColors['blue']" width="60" height="60" />
    <IconBracket class="position-absolute d-none d-sm-block style1" :color="themeColors['red']" width="60"
        height="60" />
    <IconBracket class="position-absolute style2" :color="themeColors['yellow']" width="50" height="50" />
    <IconBracket class="position-absolute style3" :color="themeColors['darkGreen']" width="100" height="100" />
</template>

<script setup>
import IconBracket from '@/components/icons/IconBracket.vue';
import { defineProps } from 'vue';
import { useTheme } from 'vuetify/lib/framework.mjs';
import BaseIdentifier from '../BaseIdentifier.vue';

const theme = useTheme();
const themeColors = theme.current.value.colors;

const props = defineProps(['nome1', 'nome', 'text'])
</script>
<style scoped>
.style {
    top: 4%;
    left: 9%;
    transform: rotate(120deg)
}

.style1 {
    top: 4.4%;
    right: 14%;
    transform: rotate(30deg);
}

.style2 {
    top: 8.5%;
    right: 4%;
    transform: rotate(-70deg);
}

.style3 {
    top: 9.5%;
    left: -1%;
    transform: rotate(-50deg);
}
</style>