<template>
    <div>
        <v-empty-state :image="require('../assets/img/logo-cor.png')" class="d-flex text-center ga-2" color=" primary"
            height="91vh">
            <v-container fluid class="text-text text-h6 font">
                <span>Whoops! Página não encontrada!</span>
            </v-container>
            <v-btn :replace="true" to="/" class="mt-4">Voltar</v-btn>
        </v-empty-state>
    </div>
</template>

<script setup>
</script>

<style scoped></style>