<template>
    <div>
        <v-btn v-if="vuetify.display.width.value < 1365" variant="outlined" color="grey-lighten-1" class="small-text"
            size="small" rounded="xl" :icon="selected.icon">
            {{ selected.lang }}
        </v-btn>
        <v-btn v-else variant="outlined" color="grey-lighten-1" class="small-text" rounded="xl">
            <v-img :src="selected.icon" width="100%" class="mr-1" rounded></v-img>
            {{ selected.lang }}
        </v-btn>
        <v-menu open-delay="100" close-delay="100" open-on-hover activator="parent" class="">
            <v-list class="bg-primary rounded-lg text-center shadow small-text">
                <v-list-item v-for=" (lan, index) in languages" :key="index" :value="lan.lang"
                    :class="[index < languages.length - 1 ? 'border-b-sm' : '', lan.lang === selected.lang ? 'bg-third' : '']"
                    @click="setLanguage(lan)">
                    <v-list-item-title class="small-text">{{ lan.nome }}
                        <div class="w-100 h-100 d-flex justify-center">
                            <div class="w-75">
                                <v-img :src="lan.icon" rounded></v-img>
                            </div>
                        </div>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const selected = computed(() => {
    return store.getters.getSelectedLanguage
})

const languages = store.getters.getAvailableLanguages

function setLanguage(language) {
    store.dispatch('setSelectedLanguage', language)
    localStorage.setItem('preferences', JSON.stringify(language))
}

</script>

<style scoped>
.shadow {
    position: relative;
    overflow: visible;
}

.shadow::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 10px 30px -12px rgba(0, 0, 0, 0.42),
        0px 4px 25px 0px rgba(0, 0, 0, 0.12),
        0px 8px 10px -5px rgba(0, 0, 0, 0.2);
    z-index: -1;
    border-radius: 0 0 8px 8px;
    clip-path: inset(20% 10% 0 0);
}
</style>