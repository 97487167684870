<template>
    <v-snackbar v-model="snackbar" timeout="-1" rounded="pill" :color="props.color" close-delay="1000" open-delay="600">
        {{ props.mensagem }}
        <template v-slot:actions>
            <v-btn color="white" variant="outlined" rounded="pill" class="border-0 elevation-4 small-text"
                @click="emits('close')">
                {{ props.mensagem1 }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue';

const props = defineProps(['snackbar', 'mensagem', 'mensagem1', 'color'])
const emits = defineEmits(['close'])

const snackbar = ref(false);

watch(() => props.snackbar, (newValue) => {
    snackbar.value = newValue;
})
</script>

<style scoped></style>