<template>
    <div>
        <div class="d-flex flex-column ga-4 w-100 border-b-md py-6 py-sm-4 normal-text">
            <div class="font-weight-bold">
                {{ props.job.header }}
            </div>
            <div class="w-sm-75 description-text">
                {{ props.job.description }}
            </div>
            <v-row no-gutters class="d-flex ga-4">
                <div v-for="(tag, index) in props.job.tags" :key="index">
                    <v-chip :prepend-icon="'mdi-' + tag.icon" color="primary" class="opacity-80">
                        {{ tag.nome }}
                    </v-chip>
                </div>

                <v-spacer></v-spacer>

                <v-btn :href="props.job.button.url" color="transparent" append-icon="mdi-arrow-top-right"
                    class="font-weight-bold small-text">
                    {{ props.job.button.title }}
                </v-btn>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['job'])
</script>

<style scoped></style>