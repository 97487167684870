const Languages = {
    af: "en", // Afrikaans
    pt: "pt", // Portuguese
    "pt-BR": "pt", // Portuguese (Brazil)
    "pt-PT": "pt", // Portuguese (Portugal)
    fr: "fr", // French
    "fr-CA": "fr", // French (Canada)
    "fr-CH": "fr", // French (Switzerland)
    "fr-FR": "fr", // French (France)
    "fr-LU": "fr", // French (Luxembourg)
    es: "es", // Spanish
    "es-AR": "es", // Spanish (Argentina)
    "es-BO": "es", // Spanish (Bolivia)
    "es-CL": "es", // Spanish (Chile)
    "es-CO": "es", // Spanish (Colombia)
    "es-CR": "es", // Spanish (Costa Rica)
    "es-CU": "es", // Spanish (Cuba)
    "es-DO": "es", // Spanish (Dominican Republic)
    "es-EC": "es", // Spanish (Ecuador)
    "es-ES": "es", // Spanish (Spain)
    "es-GQ": "es", // Spanish (Equatorial Guinea)
    "es-GT": "es", // Spanish (Guatemala)
    "es-HN": "es", // Spanish (Honduras)
    "es-MX": "es", // Spanish (Mexico)
    "es-NI": "es", // Spanish (Nicaragua)
    "es-PA": "es", // Spanish (Panama)
    "es-PE": "es", // Spanish (Peru)
    "es-PR": "es", // Spanish (Puerto Rico)
    "es-PY": "es", // Spanish (Paraguay)
    "es-SV": "es", // Spanish (El Salvador)
    "es-US": "es", // Spanish (United States)
    "es-UY": "es", // Spanish (Uruguay)
    "es-VE": "es", // Spanish (Venezuela)
    en: "en", // English
    "en-AU": "en", // English (Australia)
    "en-CA": "en", // English (Canada)
    "en-GB": "en", // English (United Kingdom)
    "en-GH": "en", // English (Ghana)
    "en-IE": "en", // English (Ireland)
    "en-IN": "en", // English (India)
    "en-JM": "en", // English (Jamaica)
    "en-KE": "en", // English (Kenya)
    "en-NG": "en", // English (Nigeria)
    "en-NZ": "en", // English (New Zealand)
    "en-PH": "en", // English (Philippines)
    "en-SG": "en", // English (Singapore)
    "en-TT": "en", // English (Trinidad and Tobago)
    "en-US": "en", // English (United States)
    "en-ZA": "en", // English (South Africa)
    "en-ZW": "en", // English (Zimbabwe)
};

export default Languages;
