<template>
    <div class="mt-12 mb-14 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16 pb-16">
        <div class="w-100 text-center main-title font-weight-bold mt-8">
            <span>
                {{ props.nome + '&nbsp;' }}
            </span>
            <span class="text-primary">
                {{ props.nome1 }}
            </span>
        </div>
        <div class="d-flex justify-center w-100 text-center mt-3">
            <span class="mt-3 normal-text w-md-75">
                {{ props.text }}
            </span>
        </div>
        <div class="mt-10">
            <v-timeline align="start">
                <v-timeline-item v-for="(year, i) in years" :key="i" :dot-color="year.color" size="small">
                    <template v-slot:opposite>
                        <div :class="`pt-1 headline font-weight-bold text-${year.color}`" v-text="year.year"></div>
                    </template>
                    <div>
                        <span :class="`mt-n1 headline font-weight-light mb-4 normal-text text-${year.color}`">
                            Lorem ipsum
                        </span>
                        <div class="description-text">
                            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut,
                            sed euismod convenire
                            principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an
                            salutandi sententiae.
                        </div>
                    </div>
                </v-timeline-item>
            </v-timeline>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';

const props = defineProps(['nome', 'nome1', 'text'])

const years = ref([
    {
        color: 'cyan',
        year: '1960',
    },
    {
        color: 'green',
        year: '1970',
    },
    {
        color: 'pink',
        year: '1980',
    },
    {
        color: 'amber',
        year: '1990',
    },
    {
        color: 'orange',
        year: '2000',
    },
])
</script>

<style scoped></style>