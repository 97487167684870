<template>
    <div class="d-flex flex-column ga-4 bg-cookies w-100 py-1 py-md-6 px-md-16 elevation-10 cookies">
        <div class="d-flex flex-column ga-2 text-justify mx-12 mx-md-10 mx-xl-16">
            <span class="normal-text font-weight-bold">
                Cookies
            </span>
            <span class="description-text">
                Este website utiliza cookies para melhorar a sua experiência de navegação. Ao continuar a navegar no
                website, está a consentir a sua utilização.
            </span>
        </div>
        <div class="d-flex w-100 mx-10 mx-xl-16">
            <v-row>
                <v-col class="d-flex ga-2">
                    <div class="justify-start normal-text">
                        <v-btn variant="tonal" class="text-green border-md border-green rounded-xl"
                            @click="cookies(true)">Aceitar</v-btn>
                    </div>
                    <div class="normal-text">
                        <v-btn variant="tonal" class="text-red border-md border-red rounded-xl"
                            @click="cookies(false)">Recusar</v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from 'vue';

const emits = defineEmits(['cookies'])

function cookies(payload) {
    emits('cookies', payload);
}
</script>

<style scoped>
.cookies::before,
.cookies::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    z-index: -1;
}

.cookies::before {
    top: -5%;
    left: -5%;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, #b52b4e5e 0%, rgba(91, 222, 255, 0) 65%);
    opacity: 0.6;

}

.cookies::after {
    top: 0%;
    right: -8%;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, #b52b4e5e 0%, rgba(91, 222, 255, 0) 65%);
    opacity: 0.60;
}
</style>