<template>
  <div>
    <div :class="[$route.name === 'careers' ? '' : 'justify-center']" class="d-flex  justify-md-start align-center">
      <div class="d-flex align-center rounded-xl px-2 py-1" :class="['bg-' + props.color]">
        <v-icon :icon="'mdi-' + props.icon + '-left'" :color="'white'" />
        <span class="description-text text-center text-white text-md-left font-weight-medium">
          {{ props.nome }}
        </span>
        <v-icon :icon="'mdi-' + props.icon + '-right'" :color="'white'" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["nome", "color", 'icon']);
// const backgroundImageStyle = computed(() => {
//   return {
//     'background-image': `url(${url})`,
//     backgroundSize: 'cover', // Ensure the image covers the container
//     backgroundPosition: 'center', // Center the background image
//     backgroundRepeat: 'no-repeat',
//     backgroundColor: '#1b488bbd' // Prevent the image from repeating
//   }
// })
</script>


<style scoped></style>
