<template>
  <div class="mt-12 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16" tile="true">
    <div class="w-100 mb-5 main-title font-weight-bold">
      <span>
        {{ props.nome + '&nbsp;' }}
      </span>
      <span class="text-primary">{{ props.nome1 }}</span>
    </div>
    <v-expansion-panels class="border-0 elevation-0">
      <v-expansion-panel v-for="sub_area in props.sub_areas" :key="sub_areas.indexOf(sub_area.title)" elevation=0
        class="my-1 v-expansion-panel--active mb-2">
        <v-expansion-panel-title class="normal-text pl-0 font-weight-medium text-text">
          {{ "0" + Number(sub_areas.indexOf(sub_area) + 1) + ". " + sub_area.title }}
        </v-expansion-panel-title>
        <v-expansion-panel-text class="description-text font-weight-regular">
          {{ sub_area.description }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(['sub_areas', 'nome', 'nome1'])
</script>

<style scoped>
.v-expansion-panel {
  border-radius: 0;
  border-bottom: 1px solid rgba(104, 104, 104, 0.753) !important;
  border: none;
  background-color: transparent !important;
}

.v-expansion-panels,
.v-expansion-panel__shadow {
  border: none !important;
  color: transparent !important;
  background-color: transparent !important;
}

.v-expansion-panel-title {
  transition: color 0.5s ease;
}

.v-expansion-panel-title--active {
  color: #2D5FA9 !important;
}

.v-expansion-panel--active:not(:first-child) {
  border: none !important;
  border-bottom: 1px solid rgba(104, 104, 104, 0.753) !important;
}

.v-expansion-panel--active:nth-child(1) {
  border: none !important;
  border-bottom: 1px solid rgba(104, 104, 104, 0.753) !important;
}

.v-expansion-panel:last-child {
  border-bottom: 1px solid rgba(104, 104, 104, 0.753) !important;
}
</style>
