<template>
    <div>
        reconhecimento
    </div>
</template>

<script setup>

</script>

<style scoped></style>