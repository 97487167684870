<template>
  <v-footer color="primary" class="d-flex flex-column elevation-10 pa-0 footer">
    <div class="circle"></div>
    <div class="circle1"></div>
    <v-container fluid class="w-100 px-14 px-md-16 py-12">
      <v-row class=" d-flex pt-5 pt-md-2 px-md-13 mx-xl-4 align-center">
        <v-col cols="12" md="2">
          <v-row class="align-center">
            <v-col cols="3" class="d-md-none"></v-col>
            <v-col cols="6" md="12" class="pa-0">
              <div>
                <v-img src="../../assets/img/logo/white-text-logo.png" rounded="0" ratio="1">
                </v-img>
              </div>
            </v-col>
            <v-col cols="3" class="d-md-none"></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7">
          <v-list class="d-flex ga-xl-4 small-text justify-center align-center bg-transparent overflow-x-hidden"
            :class="[vuetify.display.smAndUp.value ? '' : 'flex-column']">
            <v-list-item class="bg-transparent text-center text-md-start">Sobre Nós</v-list-item>
            <v-list-item class="bg-transparent text-center text-md-start">Indústrias</v-list-item>
            <v-list-item class="bg-transparent text-center text-md-start">Soluções</v-list-item>
            <v-list-item class="bg-transparent text-center text-md-start">Produtos</v-list-item>
            <v-list-item class="bg-transparent text-center text-md-start">Carreiras</v-list-item>
            <v-list-item class="bg-transparent text-center text-md-start">Notícias</v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="3"
          class="d-flex align-center justify-center justify-md-end my-3 my-md-0 ga-3 ga-sm-2 ga-lg-2 pa-0 ma-0">
          <v-btn class="background" icon="mdi-facebook"
            :size="vuetify.display.xlAndUp.value ? 'x-large' : 'large'"></v-btn>
          <v-btn class="background" icon="mdi-instagram"
            :size="vuetify.display.xlAndUp.value ? 'x-large' : 'large'"></v-btn>
          <v-btn class="background" icon="mdi-linkedin"
            :size="vuetify.display.xlAndUp.value ? 'x-large' : 'large'"></v-btn>
          <v-btn class="background" icon="mdi-youtube"
            :size="vuetify.display.xlAndUp.value ? 'x-large' : 'large'"></v-btn>
        </v-col>
        <v-row class="mt-xl-5">
          <v-container fluid
            class="d-flex w-sm-75 w-md-100 ga-5 ga-md-2 ga-lg-5 ga-xl-16 small-text justify-center px-md-6 mt-3"
            :class="[vuetify.display.mdAndUp.value ? '' : 'flex-column']">
            <v-list-item prepend-icon="mdi-map-marker" href="https://goo.gl/maps/jPm25qX9wCvFjStY9" target="_blank"
              class="rounded-xl">
              Avenida Dom Afonso Henriques 4694, Vila Nova de Famalicão
            </v-list-item>
            <v-list-item prepend-icon="mdi-phone" class="ma-0 rounded-xl">
              <v-list class="d-sm-flex ma-0 pa-0 align-center bg-transparent">
                <v-list-item class="my-0 pa-0 align-center bg-transparent rounded-xl px-md-2" href="tel:+351252116943">
                  +351 252 116 943
                </v-list-item>
                <span class="d-none d-sm-inline py-3">|</span>
                <v-list-item class="pa-0 bg-transparent rounded-xl px-md-2" href="tel:+351302002958">
                  +351 302 002 958
                </v-list-item>
              </v-list>
            </v-list-item>
            <v-list-item prepend-icon="mdi-email" href="mailto:info@iotech.pt" target="_blank" class="rounded-xl">
              info@iotech.pt
            </v-list-item>
          </v-container>
        </v-row>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <div class="w-100 bg-third px-xl-7">
      <v-container fluid class="d-flex px-md-16 z-index align-center">
        <v-row no-gutters class="align-center px-sm-10 py-2 py-md-0">
          <v-col no-gutters cols="12" sm="6" class="d-flex text-center justify-center justify-sm-start text-left">
            <div class="mini-text text-white opacity-50">
              @ {{ Data }} Copyrights by IOTech. All Rights Reserved.
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col no-gutters cols="12" sm="6" class="d-flex ga-4 ga-sm-10 align-center justify-center justify-sm-end ">
            <span @click="navigate" class="mini-text text-white opacity-50 cursor pt-5 pb-2 pt-sm-0 pb-sm-0">Termos e
              Condições</span>
            <span @click="navigate" class="mini-text text-white opacity-50 cursor pt-5 pb-2 pt-sm-0 pb-sm-0">Politica de
              Privacidade</span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-footer>
</template>

<script setup>
import vuetify from "@/plugins/vuetify";
import { computed } from "vue";

const Data = computed(() => {
  return new Date().getFullYear();
});

function navigate(event) {
  const targetText = event.target.innerText;
  if (targetText.includes('Termos e Condições')) {
    window.location.href = '/terms-and-conditions';
  } else if (targetText.includes('Politica de Privacidade')) {
    window.location.href = '/privacy-and-policy';
  }
}
</script>

<style scoped>
.footer {
  position: relative;
  overflow: hidden;
  /* Ensure footer has a z-index */
}

.footer::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.footer .circle {
  position: absolute;
  bottom: -30%;
  right: -10%;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(108, 153, 205, 0.902) 0%, rgba(45, 95, 169, 0) 65%);
  opacity: 0.5;
  content: '';
  z-index: 0;
}

.footer .circle1 {
  position: absolute;
  bottom: -25%;
  left: -8%;
  width: 350px;
  height: 300px;
  content: '';
  background: radial-gradient(circle, rgba(108, 153, 205, 0.902) 0%, rgba(91, 222, 255, 0) 65%);
  opacity: 0.4;
  z-index: 0;
}


.v-list-item {
  background: #ffffff15;
}

.background {
  background: #ffffff15 !important;
}

.cursor {
  cursor: pointer;
}
</style>
