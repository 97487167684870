<template>
  <v-app theme="iotheme" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <NavBar @drawer="setDrawer(!drawer)" />
    <TheDrawer class="justify-end" :drawer="drawer" @drawerSwitch="setDrawer(!drawer)"></TheDrawer>

    <v-main class="text-text transition mt-10 bg-background background"
      :class="$route.name === 'ioAcademy' ? 'gradient' : ''">
      <div class="circle-light-blue"></div>
      <v-fade-transition>
        <div v-if="$route.name == 'aboutus'" class="circle-light-green"></div>
      </v-fade-transition>
      <v-fade-transition>
        <div v-if="$route.name == 'careers'" class="circle-light-yellow"></div>
      </v-fade-transition>

      <router-view v-slot="{ Component }">
        <transition name="router" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>

    <v-slide-y-reverse-transition>
      <TheFooter v-intersect="onIntersect" v-if="delay" />
    </v-slide-y-reverse-transition>

    <v-slide-y-reverse-transition>
      <ArrowUp v-if="showUp && showUp1 && !drawer"
        class="d-none d-sm-flex position-fixed left-0 bottom-0 z-index ma-5" />
    </v-slide-y-reverse-transition>
    <v-slide-y-reverse-transition>
      <ChatIO v-if="showChat && !drawer" class="position-fixed right-0 bottom-0 z-index ma-5 "></ChatIO>
    </v-slide-y-reverse-transition>
    <v-slide-y-reverse-transition>
      <CookiesIO v-if="showCookies" @cookies="show_cookies" class="position-fixed bottom-0 z-index">
      </CookiesIO>
    </v-slide-y-reverse-transition>

  </v-app>

</template>


<script setup>
import NavBar from "@/components/layout/NavBar.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import ArrowUp from "@/components/ui/ArrowUp.vue";
import ChatIO from '@/components/ui/ChatIO.vue';
import CookiesIO from '@/components/ui/CookiesIO.vue';
import TheDrawer from "./components/layout/TheDrawer.vue";

import Languages from '@/assets/countries/languages.js';
import Cookies from 'js-cookie';
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const route = useRoute()
const store = useStore()
const selectedLanguage = ref(null);

onMounted(event)


const drawer = computed(() => {
  return store.getters.getDrawer
})

function setDrawer(drawer) {
  store.commit('setDrawer', drawer)
}
const showUp = ref(null);
const showUp1 = ref(true);
const showChat = ref(true);
const showCookies = ref(false);

/////////////////////////////swipe

const touchStartX = ref(0);
const touchStartY = ref(0);

function handleTouchStart(event) {
  touchStartX.value = event.touches[0].clientX;
  touchStartY.value = event.touches[0].clientY;
}

function handleTouchEnd(event) {
  const touchEndX = event.changedTouches[0].clientX;
  const touchEndY = event.changedTouches[0].clientY;
  const deltaX = touchEndX - touchStartX.value;
  const deltaY = touchEndY - touchStartY.value;

  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    if (deltaX > 0) {
      onSwipeRight();
    } else {
      onSwipeLeft();
    }
  }
}

function onSwipeLeft() {
  setDrawer(true);
}

function onSwipeRight() {
  setDrawer(false);
}
//////////////////////////////


////////////////selectedLanguage
function getLanguage() {
  const preferences = JSON.parse(localStorage.getItem("preferences")) || null;
  if (preferences != null) {
    store.commit('setSelectedLanguage', preferences);
  }
  else {
    const region = Intl.DateTimeFormat().resolvedOptions().locale;
    const language = navigator.language || navigator.userLanguage;


    if (Languages[language || region]) {
      selectedLanguage.value = Languages[language || region];
    } else {
      selectedLanguage.value = 'en';
    }

    const availableLanguages = store.getters.getAvailableLanguages
    availableLanguages.forEach((language) => {
      if (language.lang === selectedLanguage.value) {
        store.commit('setSelectedLanguage', language);
      }
    })
  }
}
getLanguage()
/////////////////

//////////////////////////////////////////////////cookies
function setConsentCookie(consent) {
  const cookieValue = JSON.stringify({
    consent,
    creationDate: new Date().toISOString()
  });
  Cookies.set('userConsent', cookieValue, { path: '/' });
}
function getConsentCookie() {
  const cookieValue = Cookies.get('userConsent');
  if (cookieValue) {
    return JSON.parse(cookieValue);
  }
  return null;
}

if (!getConsentCookie()) {
  cookies()
}
function cookies() {
  setTimeout(() => {
    showCookies.value = true;
  }, 2500)
}
function show_cookies(payload) {
  showCookies.value = !showCookies.value
  setConsentCookie(payload)
}
////////////////////////////////////////////////////////////////

function event() {
  window.addEventListener("scroll", handleScroll);
}

function handleScroll() {
  if (window.scrollY > 0) {
    showUp.value = true;
  } else {
    showUp.value = false;
  }
}
function onIntersect(isIntersecting) {
  if (isIntersecting && window.scrollY > 0) {
    showChat.value = false;
    showUp1.value = false;
  }
  else {
    showChat.value = true;
    showUp1.value = true;
  }
}

//footer
const delay = ref(false)
function showFooter() {
  setTimeout(() => {
    delay.value = true
  }, 100)
}
showFooter()

watch(() => route.name, () => {
  delay.value = false
  showFooter()
})
</script>

<style>
body::-webkit-scrollbar {
  width: 0.65em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.315);
  background: transparent !important;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.6);
  outline: 1px solid rgba(112, 128, 144, 0.55);
  border-radius: 1em;
  transition: all 0.6s ease;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(137, 137, 137, 0.849);
}


.background {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.gradient {
  background: linear-gradient(180deg, #E6DCEA, #ffffff, #ffffff);
  transition: all 10s ease;
}

.background::before {
  content: '';
  position: absolute;
  top: 7%;
  left: -10%;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(0, 102, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%);
  opacity: 0.55;
  z-index: -1;
}

.background::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -5%;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(0, 68, 204, 0.3) 0%, rgba(255, 255, 255, 0) 70%);
  opacity: 0.55;
  z-index: -1;
}

.background .circle-light-blue {
  position: absolute;
  bottom: 50%;
  right: 20%;
  width: 350px;
  height: 350px;
  background: radial-gradient(circle, rgba(0, 136, 204, 0.3) 0%, rgba(255, 255, 255, 0) 70%);
  opacity: 0.55;
  content: '';
  z-index: -1;
}

.background .circle-light-green {
  position: absolute;
  bottom: 10%;
  left: 20%;
  width: 350px;
  height: 350px;
  background: radial-gradient(circle, hsl(147, 52%, 78%) 0%, rgba(255, 255, 255, 0) 70%);
  opacity: 0.45;
  content: '';
  z-index: -1;
}

.background .circle-light-yellow {
  position: absolute;
  bottom: 20%;
  left: 20%;
  width: 350px;
  height: 350px;
  background: radial-gradient(circle, hsla(47, 100%, 78%, 0.813) 0%, rgba(255, 255, 255, 0) 70%);
  opacity: 0.45;
  content: '';
  z-index: -1;
}

.v-btn {
  opacity: 100;
}

/*router animation*/
.router-enter-active {
  animation: slide 0.6s ease-in;
}

.router-leave-active {
  animation: slide 0.6s ease-out reverse;
}


@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* */

.position {
  left: 25%;
  right: 25%;
}

/* Text font and Sizes */

.font {
  font-family: 'Montserrat', sans-serif;
}

/* Base Styles */
html,
body {
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  overflow-anchor: none;
  font-size: 16px;
  line-height: 1.5;
}

/* Typography Classes */

.intro-title {
  font-weight: 700;
  font-size: clamp(2.5rem, 5vw, 4rem);
  line-height: 1.2;
  letter-spacing: -0.015em;
}

.main-title {
  font-weight: 600;
  font-size: clamp(1.75rem, 3.5vw, 2.5rem);
  line-height: 1.3;
}

.normal-text {
  font-weight: 400;
  font-size: 1rem;
}

.description-text {
  font-weight: 400;
  font-size: 0.875rem;
}

.small-text {
  font-weight: 400;
  font-size: 0.785rem;
  line-height: 1.6;
  letter-spacing: 0.02em;
}

.mini-text {
  font-weight: 450;
  font-size: 0.695rem;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
</style>
