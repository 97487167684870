<template>
    <div>
        <IdentityIntro :nome="'Lorem Ipsum is simply'" :nome1="'dummy text'"
            :text="'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry´s standard dummy text ever since the 1500s.'" />
        <CrucialCornerstones :nome="'As bases da nossa'" :nome1="'excelência'" />
        <IOHistory :nome="'A nossa'" :nome1="'história'"
            :text="'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry´s standard'" />
        <IOCommitment :nome="'A nossa'" :nome1="'responsabilidade'" :data="data" />
    </div>
</template>

<script setup>
import CrucialCornerstones from '@/components/ui/sobrenos/CrucialCornerstones.vue';
import IdentityIntro from '@/components/ui/sobrenos/IdentityIntro.vue';
import IOCommitment from '@/components/ui/sobrenos/IOCommitment.vue';
import IOHistory from '@/components/ui/sobrenos/IOHistory.vue';
import { ref } from 'vue';

const data = ref(
    [
        {
            title: 'Lorem Ipsum is simply dummy text',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry´s standard dummy text ever since the 1500s.',
            url: 'https://img.freepik.com/fotos-premium/facilitacao-de-grupos-de-apoio-e-redes-de-pares_1169665-128854.jpg?w=1380',
            tag: 'Componente Social',
            tagColor: 'primary',
        },
        {
            title: 'Lorem Ipsum is simply dummy text',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry´s standard dummy text ever since the 1500s.',
            url: 'https://img.freepik.com/fotos-gratis/close-up-da-colecao-de-sinalizacao-ambiental_23-2148884839.jpg?t=st=1724862056~exp=1724865656~hmac=efe3d164684295605241972cc11eaa161870e72a89ba97e4285b05c445060aaf&w=1380',
            tag: 'Sustentabilidade',
            tagColor: 'green',
        }
    ]
)
</script>

<style scoped></style>