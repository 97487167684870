<template>
    <div class="mt-12 mb-12 mt-xl-16 mb-xl-16 bg-blueWhite w-100">
        <div class="px-8 px-md-16 mx-4 mx-md-10 mx-xl-16 pt-10">
            <div class="pt-md-12 pb-md-12" :class="vuetify.display.width.value <= 1000 ? 'style1' : 'style'">
                <v-img rounded="xl"
                    :src="'https://img.freepik.com/fotos-premium/um-grupo-de-jovens-empresarios-colabora-com-laptops-e-cafe-num-cafe-da-moda-durante-uma-sessao-de-trabalho-movimentada_262708-50301.jpg?w=1380'">
                </v-img>
            </div>
            <div class="w-100 mt-5 mt-sm-10 mt-md-7 mt-xl-9 main-title font-weight-bold">
                <span>
                    {{ props.nome + '&nbsp;' }}
                </span>
                <span class="text-primary">
                    {{ props.nome1 }}
                </span>
            </div>
            <v-row no-gutters justify="space-between" class="mt-10 normal-text pb-14">
                <v-col v-for="(n, index) in 3" :key="index" cols="12" sm="3">
                    <div class="d-flex ga-3 align-center">
                        <IconCard color="blue-lighten-4" class="elevation-0">
                            <v-icon :icon="'mdi-' + 'eye'" color="primary" class="main-text"></v-icon>
                        </IconCard>
                        <span class="font-weight-bold">{{ n }}</span>
                    </div>
                    <div class="w-100 mt-3 text-justify">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's.
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import IconCard from '@/components/icons/IconCard.vue';
import vuetify from '@/plugins/vuetify';
import { defineProps } from 'vue';

const props = defineProps(['nome', 'nome1'])
</script>

<style scoped>
.style {
    margin-top: -13.5rem;
}

.style1 {
    margin-top: -5.5rem;
}
</style>