<template>
  <div v-if="loaded">
    <AboutService :nome="aboutServices.header" :color="aboutServices.color" :icon="aboutServices.icon"
      :titulo="aboutServices.paragraph.header" :titulo1="aboutServices.paragraph.blueHeader"
      :topics="aboutServices.paragraph.content" :descricao="aboutServices.paragraph.content[0].text"
      :descricao1="aboutServices.paragraph.content[1].text" :descricao2="aboutServices.paragraph.content[6].text"
      :urls="[aboutServices.image, aboutServices.secondImage, aboutServices.thirdImage]" />
    <SubAreas :sub_areas="sub_areas.subarea" :nome="sub_areas.header" :nome1="sub_areas.blueHeader" />
    <ProductsIO :nome="produtos.headers.header" :nome1="produtos.headers.blueHeader" :produtos="produtos.data">
    </ProductsIO>
    <SlideGroup :nome="projetos.headers.blueHeader" :nome1="projetos.headers.header" :items="projetos.data"
      :expand="true" />
    <BannerIO :texto="banner.header" :palavras="banner.blueHeader" :url="banner.image.url" :button="banner.button">
    </BannerIO>
  </div>
  <TheSpinner v-else></TheSpinner>
</template>

<script setup>
import TheSpinner from "@/components/layout/TheSpinner.vue";
import SlideGroup from "@/components/ui/OurProjects.vue";
import AboutService from "@/components/ui/solucoes/AboutService.vue";
import BannerIO from "@/components/ui/solucoes/BannerIO.vue";
import SubAreas from "@/components/ui/solucoes/SubAreas.vue";
import ProductsIO from "../components/ui/OurProducts.vue";

import useLoad from "@/hooks/load";
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const route = useRoute();
const store = useStore();

const page = computed(() => {
  if (route.params.id === 'ciencias-de-dados-e-inteligencia-artificial') {
    return 1;
  }
  else if (route.params.id === 'interoperabilidade-e-conectividade') {
    return 2;
  }
  else if (route.params.id === 'transicao-digital') {
    return 3;
  }
  else if (route.params.id === 'gamificacao') {
    return 4;
  }
  else return 0
})

const [, , loadMultiPage, timerMultiPage] = useLoad('solutions/getCounter', 'solutions/getData', page, page)

loadMultiPage().then(timerMultiPage)

const loaded = computed(() => {
  return store.getters['solutions/getCounter'][page.value]
})

watch(() => (page.value), (pageNew, pageOld) => {
  if (pageNew != pageOld) {
    loadMultiPage().then(timerMultiPage)
  }
})

const aboutServices = computed(() => {
  return store.getters['solutions/getAboutService'][page.value];
})

const sub_areas = computed(() => {
  return store.getters['solutions/getSubareas'][page.value];
})

const produtos = computed(() => {
  return store.getters['solutions/getProdutos'][page.value];
});

const projetos = computed(() => {
  return store.getters['solutions/getProjetos'][page.value];
});

const banner = computed(() => {
  return store.getters['solutions/getBanner'][page.value];
})

</script>

<style scoped></style>
