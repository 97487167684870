<template>
    <div class="mt-12 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <v-row no-gutters class="d-flex ga-6 ga-xl-8 mb-8">
            <v-col cols="12" class="d-sm-flex main-title font-weight-bold">
                <div>
                    {{ props.nome + '&nbsp;' }}
                </div>
                <div class="text-primary">
                    {{ props.nome1 }}
                </div>
            </v-col>
            <v-col cols="12" class="normal-text">
                {{ props.texto1 }}
            </v-col>
            <v-col v-if="vuetify.display.smAndUp.value" class="d-flex ga-8" cols="12">
                <v-img :src="url" rounded="xl"></v-img>
                <v-img :src="url2" rounded="xl"></v-img>
            </v-col>
            <v-col cols="12" v-if="vuetify.display.xs.value">
                <v-img :src="url" rounded="xl"></v-img>
            </v-col>
            <v-col cols="12" v-if="vuetify.display.xs.value">
                <v-img :src="url2" rounded="xl"></v-img>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" sm="7" class="d-flex flex-column ga-6 ga-xl-8 pr-10">
                <div class="d-sm-flex main-title font-weight-bold">
                    <div>{{ props.nome2 + '&nbsp;' }}</div>
                    <div class="text-primary">{{ props.nome3 }}</div>
                </div>
                <div class="d-flex flex-column ga-3 normal-text">
                    <div>
                        {{ props.texto2 }}
                    </div>
                    <div>
                        {{ props.texto3 }}
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="5" class="mt-4 mt-sm-0">
                <v-img :src="url1" rounded="xl"></v-img>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { defineProps, ref } from 'vue';

const props = defineProps(['nome', 'nome1', 'nome2', 'nome3', 'texto1', 'texto2', 'texto3', 'images'])
const url = ref('https://img.freepik.com/fotos-premium/grupo-de-empresarios-a-discutir-um-plano-de-negocios-numa-reuniao_526067-12001.jpg?w=1480')
const url1 = ref('https://img.freepik.com/fotos-premium/reuniao-de-lancamento-do-projecto-com-as-partes-interessadas-e-os-membros-da-equipa-a-discutir-os-objectivos_1314467-672.jpg?w=826')
const url2 = ref('https://img.freepik.com/fotos-premium/reuniao-de-negocios-moderna-numa-sala-de-conferencias-luminosa-com-profissionais-a-colaborar-em-torno-de-uma-mesa-de-madeira_35681-7773.jpg?w=1480')


</script>

<style scoped>
.height {
    height: min-content;
}
</style>