import vuetify from "@/plugins/vuetify";
import AboutUs from "@/views/AboutUs.vue";
import CareersIO from "@/views/CareersIO.vue";
import IdentityIO from "@/views/IdentityIO.vue";
import IndustriesIO from "@/views/IndustriesIO.vue";
import InitialPage from "@/views/InitialPage.vue";
import IoAcademy from "@/views/IoAcademy.vue";
import NewsIO from "@/views/NewsIO.vue";
import ProductsIO from "@/views/ProductsIO.vue";
import RecognitionIO from "@/views/RecognitionIO.vue";
import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import OurSolutions from "../views/OurSolutions.vue";

const TermsConditions = () => import("@/views/TermsConditions.vue");
const PrivacyPolicy = () => import("@/views/PrivacyPolicy.vue");

const routes = [
    {
        path: "/",
        redirect: "/solutions/ciencias-de-dados-e-inteligencia-artificial",
    },
    {
        path: "/",
        name: "home",
        component: InitialPage,
    },
    {
        path: "/solutions/:id",
        name: "oursolutions",
        component: OurSolutions,
    },
    {
        path: "/careers/work-at-iotech",
        name: "careers",
        component: CareersIO,
    },
    {
        path: "/careers/ioacademy",
        name: "ioAcademy",
        component: IoAcademy,
    },
    {
        path: "/aboutus/quem-nos-somos",
        name: "aboutus",
        component: AboutUs,
    },
    {
        path: "/aboutus/a-nossa-identidade",
        name: "identety",
        component: IdentityIO,
    },
    {
        path: "/aboutus/reconhecimento",
        name: "recognition",
        component: RecognitionIO,
    },
    {
        path: "/industries/:id",
        name: "industries",
        component: IndustriesIO,
    },
    {
        path: "/products",
        name: "products",
        component: ProductsIO,
    },
    {
        path: "/news",
        name: "news",
        component: NewsIO,
    },
    {
        path: "/privacy-and-policy",
        name: "Privacy and Policy",
        component: PrivacyPolicy,
    },
    {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        component: TermsConditions,
    },
    {
        path: "/:notFound(.*)",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            const scrollOffset = 100;
            const scrollOffsetM = 80;
            const element = document.querySelector(to.hash);

            if (element) {
                const top =
                    element.getBoundingClientRect().top +
                    window.scrollY -
                    scrollOffset;
                const topM =
                    element.getBoundingClientRect().top +
                    window.scrollY -
                    scrollOffsetM;

                // Scroll to the element
                const position = {
                    top: vuetify.display.mobile.value ? topM : top,
                    behavior: "smooth",
                };

                // Remove hash after scrolling

                window.history.replaceState(
                    history.state,
                    "",
                    window.location.pathname + window.location.search
                );
                return position;
            }

            // Remove hash if element is not found
            window.history.replaceState(
                null,
                "",
                window.location.pathname + window.location.search
            );
        }
        return savedPosition || { top: 0, behavior: "smooth" };
    },
});
router.beforeEach((to, from, next) => {
    next();
});
export default router;
