<template>
    <div class="position-relative">
        <v-row no-gutters class="ga-4 ga-sm-0 mt-10 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
            <v-col cols="12" sm="9" class="d-flex flex-column justify-start ga-3">
                <BaseIdentifier :nome="props.header" :color="props.color" :icon="props.icon" />
                <div class="d-flex flex-column main-title font-weight-bold">
                    <span class>
                        {{ props.nome }}
                    </span>
                    <span class="text-primary">
                        {{ props.nome1 }}
                    </span>
                </div>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex flex-column ga-3 justify-end description-text">
                <span class="font-weight-bold">
                    {{ props.slogan }}
                </span>
                <v-btn :href="props.button.url" color="primary" class="w-50 w-sm-75 w-md-50" rounded>
                    {{ props.button.title }}
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="vuetify.display.smAndUp.value" class="d-flex ga-8 w-100 mt-5">
            <v-img :src="'https://site-api.iotechpis.com' + image.url" v-for="(image, index) in props.images" :key="index"
                rounded="xl"
                :class="[vuetify.display.xs.value ? index > 2 ? 'd-none' : '' : '', index % 2 == 0 ? 'mt-10' : ' mb-9', index == 0 ? 'ml-n16' : '', index == 3 ? 'mr-n16' : '']"
                aspect-ratio="16/9">
            </v-img>
        </div>
        <IconBracket class="d-sm-none position-absolute style4" :color="themeColors['blue']" width="60" height="60" />
        <IconBracket class="d-sm-none position-absolute style5" :color="themeColors['red']" width="60" height="60" />
        <IconBracket class="d-none d-sm-flex position-absolute style" :color="themeColors['blue']" width="60"
            height="60" />
        <IconBracket class="d-none d-sm-flex position-absolute style1" :color="themeColors['red']" width="60"
            height="60" />
        <IconBracket class="d-none d-sm-flex position-absolute style2" :color="themeColors['yellow']" width="50"
            height="50" />
        <IconBracket class="d-none d-sm-flex position-absolute style3" :color="themeColors['darkGreen']" width="65"
            height="65" />
    </div>
</template>

<script setup>
import IconBracket from '@/components/icons/IconBracket.vue';
import vuetify from '@/plugins/vuetify';
import { defineProps } from 'vue';
import { useTheme } from 'vuetify/lib/framework.mjs';
import BaseIdentifier from '../BaseIdentifier.vue';

const theme = useTheme();
const themeColors = theme.current.value.colors;

const props = defineProps(['header', 'color', 'icon', 'nome', 'nome1', 'slogan', 'button', 'images'])

</script>

<style scoped>
.style {
    bottom: -3%;
    right: 10%;
    transform: rotate(120deg);
}

.style1 {
    bottom: -1%;
    left: 30%;
    transform: rotate(20deg);
}

.style2 {
    bottom: 40%;
    right: 40%;
    transform: rotate(20deg);
}

.style3 {
    top: 46%;
    left: 10%;
    transform: rotate(78deg);
}

.style4 {
    bottom: 0%;
    right: 10%;
    transform: rotate(120deg);
}

.style5 {
    top: 65%;
    left: 10%;
    transform: rotate(20deg);
}
</style>