<template>
    <div class="mt-6 mt-md-10 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16 position-relative">
        <v-row no-gutters class="text-center text-md-left">
            <v-col v-if="route.params.id == 'smart-cities'" cols="12" :md="route.params.id === 'smart-cities' ? 6 : 5"
                class="position-relative">
                <BaseIdentifier :nome="props.data.header" :color="props.data.color" :icon="props.data.icon" />
                <div class="d-md-none py-2 mb-4 main-title font-weight-bold">
                    <span class="text-text">{{ props.data.paragraph.header }}</span>
                    <br>
                    <span class="text-secondary">{{ props.data.paragraph.blueHeader }}</span>
                </div>
                <div class="d-none d-md-block main-title font-weight-bold rounded-xl my-8 elevation-0">
                    <span class="text-text">
                        {{ props.data.paragraph.header }}
                    </span>
                    <br>
                    <span class="text-secondary">{{ props.data.paragraph.blueHeader }}</span>
                </div>
                <div class="d-flex flex-column ga-xl-4 align-center  align-md-start mb-5 description-text">
                    <v-row>
                        <v-container>
                            {{ props.data.paragraph.content[0].text }}
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-container class="mt-0 pt-0 mt-xl-n3">
                            <div class="d-flex flex-column ga-2">
                                <div>
                                    <strong>{{ props.data.paragraph.content[1].text }}</strong>
                                </div>
                                <div>
                                    {{ props.data.paragraph.content[2].text }}
                                </div>
                            </div>
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-container class=" mt-0 pt-0 mt-xl-n3">
                            <div class="d-flex flex-column ga-2">
                                <div>
                                    <strong>{{ props.data.paragraph.content[3].text }}</strong>
                                </div>
                                <div>
                                    {{ props.data.paragraph.content[4].text }}
                                </div>
                            </div>
                        </v-container>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="12" :md="route.params.id === 'smart-cities' ? 6 : 5" class="position-relative">
                <div v-if="route.params.id == 'empresas-e-industrias-4.0' && vuetify.display.smAndDown.value">
                    <BaseIdentifier :nome="props.data.header" :color="props.data.color" :icon="props.data.icon" />
                    <div class=" py-2 mb-4 main-title font-weight-bold">
                        <span class="text-text">{{ props.data.paragraph.header }}</span>
                        <br>
                        <span class="text-secondary">{{ props.data.paragraph.blueHeader }}</span>
                    </div>
                </div>
                <div fluid class="d-flex flex-column align-top align-center w-100"
                    :class="[route.params.id == 'empresas-e-industrias-4.0' ? 'align-lg-start' : 'align-lg-end', vuetify.display.mobile.value ? 'mb-6' : '']">
                    <v-img :src="'https://site-api.iotechpis.com' + props.data.image.url"
                        :width="route.params.id == 'empresas-e-industrias-4.0' ? '83%' : '75%'"
                        class=" rounded-xl"></v-img>
                </div>
                <div v-if="route.params.id == 'smart-cities'">
                    <IconBracket class="position-absolute style" :color="themeColors['blue']" width="70" height="70" />
                    <IconBracket class="position-absolute style1" :color="themeColors['red']" width="90" height="90" />
                    <IconBracket class="position-absolute style2" :color="themeColors['yellow']" width="50"
                        height="50" />
                    <IconCard class="position-absolute style4">
                        <IconCar width="50" height="50"></IconCar>
                    </IconCard>
                    <IconCard class="position-absolute style5">
                        <IconBus width="50" height="50"></IconBus>
                    </IconCard>
                    <IconCard class="position-absolute style6">
                        <IconBuilding width="66" height="66"></IconBuilding>
                    </IconCard>
                </div>
                <div v-if="route.params.id == 'empresas-e-industrias-4.0'">
                    <IconCard class="position-absolute style7" color="lightRed">
                        <IconChart width="45" height="45" :color="themeColors[props.data.color]" />
                    </IconCard>
                    <IconCard class="position-absolute style8" color="lightRed">
                        <IconGears width="55" height="55" :color="themeColors[props.data.color]" />
                    </IconCard>
                    <IconCard class="position-absolute style9" color="lightRed">
                        <IconIndustrie width="65" height="65" :color="themeColors[props.data.color]"></IconIndustrie>
                    </IconCard>
                </div>
            </v-col>
            <v-col v-if="route.params.id == 'empresas-e-industrias-4.0'" cols="12" md="7"
                class="justify-start position-relative">
                <BaseIdentifier class="d-none d-md-flex" :nome="props.data.header" :color="props.data.color"
                    :icon="props.data.icon" />
                <div class="d-none d-md-flex rounded-xl my-8  elevation-0">
                    <b class="main-title font-weight-bold">
                        <span class="text-text">{{ props.data.paragraph.header }}</span>
                        <br>
                        <span class="text-secondary">{{ props.data.paragraph.blueHeader }}</span>
                    </b>
                </div>
                <div class="d-flex flex-column ga-xl-4 description-text align-center  align-md-start">
                    <v-row>
                        <v-container class="">
                            {{ props.data.paragraph.content[0].text }}
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-container class="mt-0 pt-0 mt-xl-n3">
                            <div class="d-flex flex-column ga-2">
                                <div>
                                    <strong>{{ props.data.paragraph.content[1].text }}</strong>
                                </div>
                                <div>
                                    {{ props.data.paragraph.content[2].text }}
                                </div>
                            </div>
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-container class=" mt-0 pt-0 mt-xl-n3">
                            <div class="d-flex flex-column ga-2">
                                <div>
                                    <strong> {{ props.data.paragraph.content[3].text }}</strong>
                                </div>
                                <div>
                                    {{ props.data.paragraph.content[4].text }}
                                </div>
                            </div>
                        </v-container>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <IconBracket v-if="route.params.id == 'smart-cities'" class="position-absolute style3"
            :color="themeColors['darkGreen']" width="130" height="130" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { useTheme } from 'vuetify/lib/framework.mjs';

import IconBracket from '@/components/icons/IconBracket.vue';
import IconBuilding from '@/components/icons/IconBuilding.vue';
import IconBus from '@/components/icons/IconBus.vue';
import IconCar from '@/components/icons/IconCar.vue';
import IconCard from '@/components/icons/IconCard.vue';
import IconChart from "@/components/icons/IconChart.vue";
import IconGears from '@/components/icons/IconGears.vue';
import IconIndustrie from '@/components/icons/IconIndustrie.vue';
import vuetify from '@/plugins/vuetify';
import BaseIdentifier from '../BaseIdentifier.vue';

const theme = useTheme();
const themeColors = theme.current.value.colors;

const props = defineProps(['data'])

const route = useRoute();
</script>

<style scoped>
.z-index {
    z-index: 1;
}

.style {
    top: 10%;
    left: 15%;
    transform: rotate(-110deg)
}

.style1 {
    bottom: -2%;
    transform: rotate(-50deg);
}

.style2 {
    bottom: 2%;
    left: 65%;
    transform: rotate(30deg);
}

.style3 {
    bottom: -5%;
    right: -5%;
    transform: rotate(120deg);
}

.style4 {
    bottom: 40%;
    right: -2%;
}

.style5 {
    left: 40%;
    bottom: -3%;
}

.style6 {
    left: 12%;
    bottom: 25%;
}

.style7 {
    right: 50%;
    bottom: -0.75%;
}

.style8 {
    left: -3.5%;
    bottom: 50%;
}

.style9 {
    right: 10%;
    bottom: 10%;
}
</style>