<template>
    <v-card class="elevation-5  d-flex justify-center align-center rounded-lg pa-3 estilo"
        :class="[props.color ? `bg-${props.color}` : 'bg-blueWhite']">
        <slot></slot>
    </v-card>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['color'])

</script>

<style scoped>
.estilo {
    width: min-content;
    height: min-content;
}
</style>