const industries = {
    namespaced: true,
    state: {
        intro: [],
        Carrousel: [],
        produtos: [],
        projetos: [],
        clientes: [],
        counter: [],
    },
    getters: {
        getCounter(state) {
            return state.counter;
        },
        getIntro(state) {
            return state.intro;
        },
        getCarrousel(state) {
            return state.Carrousel;
        },
        getProdutos(state) {
            return state.produtos;
        },
        getProjetos(state) {
            return state.projetos;
        },
        getClientes(state) {
            return state.clientes;
        },
    },
    mutations: {
        setCounter(state, payload) {
            state.counter[payload] = true;
        },
        setIntro(state, payload) {
            state.intro[payload.id] = {
                data: { ...payload.data },
            };
        },
        setCarrousel(state, payload) {
            state.Carrousel[payload.id] = {
                data: { ...payload.data },
            };
        },
        setProdutos(state, payload) {
            state.produtos[payload.id] = {
                headers: payload.headers,
                data: { ...payload.data },
            };
        },
        setProjetos(state, payload) {
            state.projetos[payload.id] = {
                headers: payload.headers,
                data: { ...payload.data },
            };
        },
        setClientes(state, payload) {
            state.clientes[payload.id] = {
                headers: payload.headers,
                data: { ...payload.data },
            };
        },
    },
    actions: {
        async getData(context, payload) {
            console.log(context.rootGetters.getSelectedLanguage);
            const data = await fetch(
                process.env.VUE_APP_INDUSTRIES_PAGE.replace("id", payload)
            );
            if (data.ok) {
                const json = await data.json();
                console.log(json);
                context.commit("setIntro", {
                    id: payload,
                    data: { ...json.industryPage.sections[0] },
                });
                context.commit("setCarrousel", {
                    id: payload,
                    data: { ...json.industryPage.sections[1] },
                });
                context.commit("setProdutos", {
                    id: payload,
                    headers: json.industryPage.sections[2],
                    data: json.relatedProducts,
                });
                context.commit("setProjetos", {
                    id: payload,
                    headers: json.industryPage.sections[3],
                    data: json.relatedProjects,
                });
                context.commit("setClientes", {
                    id: payload,
                    headers: json.industryPage.sections[4],
                    data: json.relatedClients,
                });
                context.commit("setCounter", payload);
            } else {
                alert(data.status);
            }
        },
    },
    modules: {},
};

export default industries;
