<template>
    <div v-if="loaded">
        <MakeADifference :header="intro.header" :color="intro.color" :icon="intro.icon"
            :nome="intro.paragraph[0].header" :nome1="intro.paragraph[0].blueHeader"
            :slogan="intro.paragraph[0].content[0].text" :button="intro.button" :images="intro.images" />
        <IOExperience :nome="intro.paragraph[1].header" :nome1="intro.paragraph[1].blueHeader"
            :texto1="intro.paragraph[1].content[0].text" :nome2="intro.paragraph[2].header"
            :nome3="intro.paragraph[2].blueHeader" :texto2="intro.paragraph[2].content[0].text" :texto3="texto4" />
        <AreasIO :nome="areas.header" :nome1="areas.blueHeader" :colunas="4" :areas="areas.areas"
            :color="areas.color" />
        <OpenPositions :nome="jobs.header" :nome1="jobs.blueHeader" :texto="jobs.description" :selecao="jobs.filters"
            :jobs="jobs.c_openings" id="positions" />
        <FormsIO :nome="form.header" :nome1="form.blueHeader" :form="form" @open="openSnackBar" />
        <SnackBar :snackbar="snackbar" :color="color" :mensagem="snackbarmessage" :mensagem1="snackbarmessage1"
            @close="closeSnackBar" />
    </div>
    <TheSpinner v-else></TheSpinner>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import SnackBar from '@/components/layout/SnackBar.vue';
import TheSpinner from '@/components/layout/TheSpinner.vue';
import AreasIO from '@/components/ui/carreiras/AreasIO.vue';
import FormsIO from '@/components/ui/carreiras/FormsIO.vue';
import IOExperience from '@/components/ui/carreiras/IOExperience.vue';
import MakeADifference from '@/components/ui/carreiras/MakeADifference.vue';
import OpenPositions from '@/components/ui/carreiras/OpenPositions.vue';

import useLoad from '@/hooks/load';

const store = useStore()

const page = ref(0)
const pageId = ref('iocareers')
const [, , loadMultiPage, timerMultiPage] = useLoad('careers/getCounter', 'careers/getData', page, pageId)

///////////
loadMultiPage().then(timerMultiPage)

const loaded = computed(() => {
    return store.getters['careers/getCounter'][0]
})

watch(() => loaded.value, () => {
    if (!loaded.value) {
        loadMultiPage().then(timerMultiPage)
    }
})
///////////////

const intro = computed(() => {
    return store.getters['careers/getIntro']
})
const areas = computed(() => {
    return store.getters['careers/getAreas'][0]
})
const jobs = computed(() => {
    return store.getters['careers/getPosicoesTrabalho']
})
const form = computed(() => {
    return store.getters['careers/getContact']
})

const texto4 = ref('Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.')

// const selecao = ref([
//     {
//         nome: 'Ver todos',
//         filtro: '',
//     },
//     {
//         nome: 'Desenvolvimento',
//         filtro: 'Dev',
//     },
//     {
//         nome: 'Ciência de dados',
//         filtro: 'DC',
//     },
//     {
//         nome: 'UI/UX Design',
//         filtro: 'UI',
//     },
//     {
//         nome: 'Marketing',
//         filtro: 'MK',
//     },
// ])


const snackbar = ref(false)
const snackbarmessage = ref(null)
const snackbarmessage1 = ref(null)
const color = ref(null)
function closeSnackBar() {
    snackbar.value = !snackbar.value
}
function openSnackBar(value) {
    if (value) {
        snackbarmessage.value = !vuetify.display.mobile.value ? 'Candidatura submetida com sucesso. Ire-mos entrar em contacto em breve!' : 'Enviada!'
        snackbarmessage1.value = 'Enviado!'
        color.value = '#5E9B4E'
        snackbar.value = true
    }
    else {
        snackbarmessage.value = !vuetify.display.mobile.value ? 'Erro de envio! Algo de inesperado ocorreu!' : 'Erro!'
        snackbarmessage1.value = 'Tente novamente!'
        color.value = '#B52B4D'
        snackbar.value = true
    }
}
</script>

<style lang="scss" scoped></style>