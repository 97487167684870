const careers = {
    namespaced: true,
    state: {
        //careers
        intro: {},
        posicoesTrabalho: {},
        contact: {},
        //
        areas: [],
        //
        //ioAcademy
        floatingCards: [],
        introA: {},
        oferta: {},
        areasA: [],
        requisitos: {},
        comments: {},
        form: {},
        counter: [],
    },
    getters: {
        //careers
        getIntro(state) {
            return state.intro;
        },
        getPosicoesTrabalho(state) {
            return state.posicoesTrabalho;
        },
        getContact(state) {
            return state.contact;
        },
        //
        getCounter(state) {
            return state.counter;
        },
        getAreas(state) {
            return state.areas;
        },
        //
        //ioAcademy
        getFloatingCards(state) {
            return state.floatingCards;
        },
        getIntroA(state) {
            return state.introA;
        },
        getOferta(state) {
            return state.oferta;
        },
        getAreasA(state) {
            return state.areasA;
        },
        getRequisitos(state) {
            return state.requisitos;
        },
        getComments(state) {
            return state.comments;
        },
        getForm(state) {
            return state.form;
        },
    },
    mutations: {
        //careers
        setIntro(state, payload) {
            state.intro = { ...payload };
        },
        setPosicoesTrabalho(state, payload) {
            state.posicoesTrabalho = { ...payload };
        },
        setContact(state, payload) {
            state.contact = { ...payload };
        },
        //
        setCounter(state, payload) {
            const index = payload === "ioacademy" ? 1 : 0;
            state.counter[index] = true;
        },
        setAreas(state, payload) {
            const index = payload.id === "ioacademy" ? 1 : 0;
            state.areas[index] = { ...payload.data };
        },
        //
        //ioAcademy
        setFloatingCards(state, payload) {
            state.floatingCards = { ...payload };
        },
        setIntroA(state, payload) {
            state.introA = { ...payload };
        },
        setOferta(state, payload) {
            state.oferta = { ...payload };
        },
        setRequisitos(state, payload) {
            state.requisitos = { ...payload };
        },
        setComments(state, payload) {
            state.comments = { ...payload };
        },
        setForm(state, payload) {
            state.form = { ...payload };
        },
    },
    actions: {
        async getData(context, payload) {
            const data = await fetch(
                process.env.VUE_APP_CAREERS_PAGE.replace("id", payload)
            );
            if (data.ok && payload === "iocareers") {
                const json = await data.json();
                console.log(json);

                context.commit("setIntro", json.careersPage.intro);
                context.commit("setAreas", {
                    id: payload,
                    data: json.careersPage.areasSection,
                });
                context.commit(
                    "setPosicoesTrabalho",
                    json.careersPage.jobOpenings
                );
                context.commit("setContact", json.careersPage.contact);
                context.commit("setCounter", payload);
            } else if (data.ok && payload === "ioacademy") {
                const json = await data.json();
                console.log(json.ioAcademyPage);

                context.commit(
                    "setFloatingCards",
                    json.ioAcademyPage.floatingCards
                );
                context.commit("setIntroA", json.ioAcademyPage.intro);
                context.commit("setOferta", json.ioAcademyPage.offers);
                context.commit("setAreas", {
                    id: payload,
                    data: json.ioAcademyPage.areasSection,
                });
                context.commit("setRequisitos", json.ioAcademyPage.admission);
                context.commit("setComments", json.ioAcademyPage.feedback);
                context.commit("setForm", json.ioAcademyPage.form);
                context.commit("setCounter", payload);
            } else {
                alert(data.status);
            }
        },
    },
    modules: {},
};

export default careers;
