<template>
  <div class="mt-12 mb-16 mt-xl-16 mb-xl-16 w-100 transition" :style="{ height: height }" id="div">
    <v-row>
      <v-col cols="12">
        <div class="px-8 px-md-16 mx-4 mx-md-10 mx-xl-16" :class="[props.justify ? 'text-center' : '', props.justify]">
          <div class="font-weight-bold main-title text-wrap"
            :class="vuetify.display.xs.value ? 'd-flex flex-column' : ''">
            <span class="text-primary">{{ props.nome + '&nbsp;' }}</span>
            <span>
              {{ props.nome1 }}
            </span>
          </div>
          <div v-if="props.texto" class="text-wrap normal-text my-3 my-md-2">{{ props.texto }}</div>
          <div v-if="props.texto1" class="text-wrap mb-4 mt-4 normal-text text-wrap">{{ props.texto1 }}</div>
        </div>
      </v-col>
      <v-col cols="12" id="padding" @touchstart="blockTouch" @touchend="blockTouch">
        <div id="padding1">
          <v-slide-group v-model="item_selecionado" :center-active="true"
            class="ma-0 pl-0 mt-n4 ml-md-n1 ml-lg-n14 mr-lg-n14" :selected-class="props.expand ? 'pa-1' : ''"
            show-arrows="desktop" next-icon="" prev-icon="" :mandatory="route.name === 'ioAcademy' ? true : false">
            <v-slide-group-item v-for="(item, index) in props.items" :key="index"
              v-slot="{ isSelected, toggle, selectedClass }" :value="flip[index]">
              <div @click="flipper(index)"
                :class="[route.name === 'aboutus' && !vuetify.display.mobile.value ? 'flip-card' : '']">
                <div :class="['mt-3', 'mr-5', selectedClass, 'rounded-xl', dimensions, 'transition_padding', 'hover', route.name === 'aboutus' ? 'bg-blueWhite flip-card-inner' : 'bg-' + props.bg,
                  isSelected && route.name === 'ioAcademy' || !item_selecionado && index === 0 && route.name === 'ioAcademy' ? 'gradient' : '',
                  flip[index] ? 'flip-mobile' : '']" class="d-flex transition" @click="toggle(), toggle1(isSelected)">
                  <v-img :src="item.image ? 'https://site-api.iotechpis.com' + item.image.url : ''" class="rounded-xl "
                    :class="route.name === 'aboutus' ? 'front' : ''" :gradient="props.bg ? '' : '#00000015, #000000b2'"
                    cover>
                    <div class="d-flex flex-column w-100 fill-height  justify-space-between">
                      <div class="d-flex w-100 fill-height align-center justify-center position-absolute">
                        <v-scale-transition>
                          <v-icon v-if="isSelected && props.expand" color="background" icon="mdi-close-circle-outline"
                            size="48"></v-icon>
                        </v-scale-transition>
                      </div>
                      <div class="d-flex flex-column ma-3 ma-md-4 mb-3 mb-sm-4 small-text">
                        <span v-if="route.name === 'ioAcademy'"
                          :class="isSelected || !item_selecionado && index === 0 ? 'text-purple' : 'text-grey'">{{
                            item.header }}</span>
                        <span v-if="route.name === 'ioAcademy'" class="mt-2 mb-4">
                          {{ item.description }}
                        </span>
                      </div>

                      <div class="d-flex ma-3 ma-md-4 mb-3 mb-sm-4 small-text"
                        :class="[props.bg, props.bg ? 'text-text rounded-lg' : 'text-white', route.name == 'ioAcademy' ? '' : 'pa-1 pa-md-2']">
                        <div class="d-flex flex-column justify-end">
                          <span :class="[props.bg ? 'font-weight-bold' : 'mini-text']">
                            {{ item.name && item.header ? item.header : item.name }}
                          </span>
                          <span :class="[props.bg ? 'mini-text' : 'font-weight-bold']">
                            {{ item.position || item.name }}
                          </span>
                        </div>
                        <v-spacer v-if="$route.name == 'ioAcademy'"></v-spacer>
                        <div v-if="$route.name == 'ioAcademy'" class="">
                          <v-icon icon="mdi-comma" class="mr-n2"
                            :color="isSelected || !item_selecionado && index === 0 ? 'purple' : ''"></v-icon>
                          <v-icon icon="mdi-comma"
                            :color="isSelected || !item_selecionado && index === 0 ? 'purple' : ''"></v-icon>
                        </div>
                      </div>
                    </div>
                  </v-img>
                  <div v-if="route.name === 'aboutus'" class="back justify-center align-center">
                    (back)
                  </div>
                </div>
              </div>
            </v-slide-group-item>
          </v-slide-group>

          <v-slide-y-reverse-transition>
            <v-sheet v-if="item_selecionado != null && props.expand"
              class="d-flex mb-16 small-text bg-transparent mr-md-9 px-14 px-md-16 mr-xl-16">
              <div class="w-100 text-center text-justify text-md-left">
                <div class="d-flex fill-height align-center">
                  <h3 class="normal-text text-md-left">
                    {{ items[item_selecionado].description }}
                  </h3>
                </div>
                <div class="w-100 text-center mt-5 mt-md-2 mt-xl-5">
                  <!-- adicionar link a item -->
                  <v-btn :to="items[item_selecionado].button.isExternal ? '' : items[item_selecionado].button.url"
                    :href="items[item_selecionado].button.isExternal ? items[item_selecionado].button.url : ''"
                    :target="items[item_selecionado].button.isExternal ? '_blank' : ''">
                    {{ items[item_selecionado].button.title }}
                  </v-btn>
                </div>
              </div>
            </v-sheet>
          </v-slide-y-reverse-transition>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import vuetify from "@/plugins/vuetify";
import { computed, defineProps, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";


onMounted(getsize)

const route = useRoute()

//swipe block call
function blockTouch(event) {
  event.stopPropagation();
  // Do nothing
}
//

const item_selecionado = ref(null);
const props = defineProps(["nome", "nome1", 'texto', 'texto1', 'justify', 'expand', 'bg', "items"]);
const expanded = ref(null);


const dimensions = computed(() => {
  if (vuetify.display.smAndDown.value && route.name != 'ioAcademy') {
    return 'dimensonssm';
  }
  else if (route.name != 'ioAcademy') {
    return 'dimensions'
  }
  if (vuetify.display.smAndUp.value && route.name === 'ioAcademy') { return 'dimensions-io' }
  else return 'dimensions-io-sm'
})

function toggle1(isSelected) {
  expanded.value = !isSelected
}

let original_size = ref(200)
function getsize() {
  original_size.value = (document.getElementById('div').scrollHeight);
  const addPadding = (document.getElementsByClassName('v-slide-group__content'))
  const addPadding1 = (document.getElementById('padding'))
  const addPadding2 = (document.getElementById('padding1'))
  if (vuetify.display.xlAndUp.value) {
    addPadding[0].classList.add('pl-16');
    addPadding1.classList.add('pl-16');
    addPadding2.classList.add('pl-4')
  }
  else if (vuetify.display.mdAndUp.value) {
    addPadding[0].classList.remove(['pl-16', 'pl-12']);
    addPadding1.classList.remove(['pl-16', 'pl-14'])
    addPadding[0].classList.add('pl-16');
    addPadding1.classList.add('pl-14');
  }
  else {
    addPadding[0].classList.add('pl-12');
  }
}

watch(() => vuetify.display.width.value, () => {
  getsize()
})



const height = computed(() => {
  if (expanded.value && props.expand && vuetify.display.width.value > 427 && vuetify.display.width.value < 431) {

    return original_size.value + 0.95 * original_size.value + 'px'
  }
  else if (expanded.value && props.expand && vuetify.display.sm.value) {
    return original_size.value + 0.75 * original_size.value + 'px'
  }
  else if (expanded.value && props.expand && vuetify.display.xs.value) {
    return original_size.value + 1.05 * original_size.value + 'px'
  }
  else if (expanded.value && props.expand && vuetify.display.mdAndUp.value) {
    return original_size.value + 0.5 * original_size.value + 'px'
  }
  else return original_size.value + 'px'

})

const flip = ref([])

function flipper(index) {
  if (vuetify.display.mobile.value && route.name === 'aboutus') {
    flip.value[index] = !flip.value[index]
  }
}
</script>

<style scoped>
.dimensions {
  height: 13.8em;
  width: 13.8em;
  perspective: 1000px;
}

.dimensions-io {
  height: 17.8em;
  width: 22.8em;
  perspective: 1000px;
}

.dimensonssm {
  height: 11em;
  width: 11em;
  perspective: 1000px;
}

.dimensions-io-sm {
  height: 16.8em;
  width: 16.8em;
  perspective: 1000px;
}

.transition_padding {
  transition: padding 0.2s ease;
}

.transition {
  transition: all 0.2s ease-in-out;
}

.gradient {
  background: linear-gradient(to right, #F5F5F5, #f0eded, #E9E5F0, #EDEDF6);
}

.z-index {
  z-index: 1000;
}

.left {
  left: 3%;
  bottom: 0%;
}

.bass {
  background: rgba(255, 255, 255, 0.70);
  backdrop-filter: blur(10px);

}

.hover {
  cursor: pointer;
}


.flip-card-inner {
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-mobile {
  transform: rotateY(180deg);
}

.front,
.back {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}
</style>