const news = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {},
};

export default news;
