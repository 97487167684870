const solutions = {
    namespaced: true,
    state: {
        aboutService: [],
        subareas: [],
        projetos: [],
        produtos: [],
        banner: [],
        counter: [],
    },
    getters: {
        getCounter(state) {
            return state.counter;
        },
        getSubareas(state) {
            return state.subareas;
        },
        getAboutService(state) {
            return state.aboutService;
        },
        getProdutos(state) {
            return state.produtos;
        },
        getProjetos(state) {
            return state.projetos;
        },
        getBanner(state) {
            return state.banner;
        },
    },
    mutations: {
        setCounter(state, payload) {
            state.counter[payload] = true;
        },
        setAboutService(state, payload) {
            state.aboutService[payload.id] = { ...payload.data };
        },
        setSubareas(state, payload) {
            state.subareas[payload.id] = { ...payload.data };
        },
        setProdutos(state, payload) {
            state.produtos[payload.id] = {
                headers: payload.headers,
                data: { ...payload.data },
            };
        },
        setProjetos(state, payload) {
            state.projetos[payload.id] = {
                headers: payload.headers,
                data: { ...payload.data },
            };
        },
        setBanner(state, payload) {
            state.banner[payload.id] = {
                ...payload.data,
            };
        },
    },
    actions: {
        async getData(context, payload) {
            const data = await fetch(
                process.env.VUE_APP_SOLUTIONS_PAGE.replace("id", payload)
            );
            if (data.ok) {
                const json = await data.json();
                console.log(json);
                context.commit("setAboutService", {
                    id: payload,
                    data: json.solutionPage.sections[0],
                });
                context.commit("setSubareas", {
                    id: payload,
                    data: json.solutionPage.sections[1],
                });
                context.commit("setProdutos", {
                    id: payload,
                    headers: json.solutionPage.sections[2],
                    data: json.relatedProducts,
                });
                context.commit("setProjetos", {
                    id: payload,
                    headers: json.solutionPage.sections[3],
                    data: json.relatedProjects,
                });
                context.commit("setBanner", {
                    id: payload,
                    data: json.solutionPage.sections[4],
                });
                context.commit("setCounter", payload);
            } else {
                alert(data.status);
            }
        },
    },
    modules: {},
};

export default solutions;
