<template>
    <div class="mt-6 mt-md-10 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16 position-relative">
        <v-row class="text-center text-md-left">
            <div class="d-md-none w-100">
                <BaseIdentifier :nome="props.intro.header" :color="props.intro.color" :icon="props.intro.icon" />
                <div class="bg-transparent d-md-none py-2 main-title font-weight-bold">
                    <span class="text-text">{{ props.intro.paragraph.header }}</span>
                    <br>
                    <span class="text-secondary">
                        {{ props.intro.paragraph.blueHeader }}
                    </span>
                </div>
            </div>
            <v-col cols="12" md="5" class="position-relative">
                <div fluid class="d-flex flex-column align-center align-lg-start w-100">
                    <v-img :src="'https://site-api.iotechpis.com' + props.intro.image.url" width="100%"
                        class="rounded-xl"></v-img>
                </div>
                <IconBracket class="position-absolute style" :color="themeColors['blue']" width="60" height="60" />
                <IconBracket class="position-absolute style1" :color="themeColors['red']" width="60" height="60" />
                <IconBracket class="position-absolute style2" :color="themeColors['yellow']" width="50" height="50" />
                <IconBracket class="position-absolute style3" :color="themeColors['darkGreen']" width="100"
                    height="100" />
            </v-col>
            <v-col cols="12" md="7" class="position-relative pl-8">
                <div class="d-none d-md-flex">
                    <BaseIdentifier :nome="props.intro.header" :color="props.intro.color" :icon="props.intro.icon" />
                    <div class="bg-transparent d-md-none py-2 mb-4 main-title">
                        <span class="text-text ">{{ props.intro.paragraph.header }}</span>
                        <span class="text-secondary">{{ props.intro.paragraph.blueHeader }}</span>
                    </div>
                </div>
                <v-card class="bg-transparent d-none d-md-block bg-white my-5 elevation-0">
                    <b class="main-title font-weight-bold">
                        <span class="text-text">
                            {{ props.intro.paragraph.header }}
                        </span>
                        <br>
                        <span class="text-secondary">{{ props.intro.paragraph.blueHeader }}</span>
                    </b>
                </v-card>
                <div class="d-flex flex-column ga-xl-4 text-xl-h4 align-center align-lg-start">
                    <v-row>
                        <v-container class="description-text">
                            {{ props.intro.paragraph.content[0].text }}
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-container class="mt-0 pt-0 mt-xl-n3">
                            <div class="d-flex flex-column ga-2 description-text">
                                <div>
                                    <strong>{{ props.intro.paragraph.content[1].text }}</strong>
                                </div>
                                <div>
                                    {{ props.intro.paragraph.content[2].text }}
                                </div>
                            </div>
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-container class=" mt-0 pt-0 mt-xl-n3">
                            <div class="d-flex flex-column ga-2 description-text">
                                <div>
                                    <strong>{{ props.intro.paragraph.content[3].text }}</strong>
                                </div>
                                <div>
                                    {{ props.intro.paragraph.content[4].text }}
                                </div>
                            </div>
                        </v-container>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useTheme } from 'vuetify/lib/framework.mjs';

import IconBracket from '@/components/icons/IconBracket.vue';
import BaseIdentifier from '../BaseIdentifier.vue';

const theme = useTheme();
const themeColors = theme.current.value.colors;

const props = defineProps(['intro'])
</script>

<style scoped>
.z-index {
    z-index: 1;
}

.style {
    top: 10%;
    left: -5%;
    transform: rotate(120deg)
}

.style1 {
    top: 10%;
    right: -3.5%;
    transform: rotate(40deg);
}

.style2 {
    bottom: -2.5%;
    right: 20%;
    transform: rotate(30deg);
}

.style3 {
    bottom: 20%;
    left: -16%;
    transform: rotate(-40deg);
}
</style>