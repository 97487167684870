<template>
    <div class="mt-12 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <v-row no-gutters>
            <v-col cols=12 class="text-center text-md-start mb-sm-5 mb-md-0">
                <h2 class="main-title font-weight-bold text-wrap">
                    <span>
                        {{ props.nome + '&nbsp;' }}
                    </span>
                    <br v-if="vuetify.display.xs.value">
                    <span class="text-primary">{{ props.nome1 }}</span>
                </h2>
                <div class="mt-2 normal-text">{{ props.texto.text }}</div>
            </v-col>
        </v-row>
        <v-row no-gutters justify="space-between"
            :class="[vuetify.display.lgAndUp.value ? 'vertical-text mt-5' : 'ga-3 align-center mt-5']"
            class="d-flex flex-column w-100 normal-text text-white text-start font-weight-bold">
            <v-col cols="12" md="9" :lg="!isSelected[index] ? '1' : '5'" v-for="(passo, index) in props.passos"
                :key="index" @click="open(index)" :class="vuetify.display.mobile.value ? '' : 'expand'">
                <div class="w-100 d-flex align-center"
                    :class="[vuetify.display.smAndUp.value ? !isSelected[index] ? 'background' : 'bg-transparent' : !isSelected[index] ? 'background-sm' : 'bg-transparent',
                    vuetify.display.lgAndUp.value && isSelected[index] ? 'horizontal-text' : '',
                    !isSelected[index] ? 'bg-primary py-2 py-md-6' : 'pa-0 ma-0', !isSelected[index] ? 'pointer rounded-xl' : '', vuetify.display.mobile.value ? 'expand-sm rounded-xl' : '']"
                    :style="{ maxheight: vuetify.display.mobile.value ? !isSelected[index] ? 50 + 'px' : 370 + 'px' : '' }">
                    <div v-show="!isSelected[index]" class="px-6">
                        0{{ index + 1 }}. {{ passo.header }}
                    </div>
                    <div v-show="isSelected[index]">
                        <div :class="[isSelected[index] ? 'bg-primary background3 ' : '']"
                            class="d-flex flex-column ga-1 ga-xl-3 text-left w-100 h-50 pa-6 rounded-t-xl rounded-be-xl py-xl-10 ">
                            0{{ index + 1 }}. {{ passo.header }}
                            <span class="description-text">
                                {{ passo.description }}
                            </span>
                        </div>
                        <div class="w-100 h-50 d-flex ma-0 pa-0">
                            <v-col no-gutters cols="5" xl="6"
                                class="bg-primary background2 ma-0 pa-0 rounded-b-xl radius-te">
                            </v-col>
                            <v-col no-gutters cols="7" xl="6"
                                class="ma-0 pt-2 pt-md-4 pl-2 pl-md-4 pb-0 pr-0 bg-transparent">
                                <v-img aspect-ratio="1" class="ma-0 rounded-ts-0" gradient="#1B3F6B40, #1B3F6B70" cover
                                    :src="'https://site-api.iotechpis.com' + passo.image.url">
                                </v-img>
                            </v-col>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { defineProps, ref } from 'vue';

const props = defineProps(['nome', 'nome1', 'texto', 'passos'])


const isSelected = ref([true, false, false, false, false, false])
function open(index) {
    isSelected.value.forEach((value) => {
        isSelected.value[isSelected.value.indexOf(value)] = false;
    })
    isSelected.value[index] = !isSelected.value[index]
}

</script>

<style scoped>
.expand {
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: linear;
}

.expand-sm {
    transition: all 0.3s linear;
}


.pointer {
    cursor: pointer;
}

.vertical-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.horizontal-text {
    writing-mode: horizontal-tb;
    text-align: center;
    transform: rotate(180deg);
}

.background {
    background: linear-gradient(to bottom, #e0efff60, #3476b8);
}

.background-sm {
    background: linear-gradient(to right, #e0efff3c, #3476b8);
}

.background1 {
    background: linear-gradient(to top, #e0efff3c, #3476b8)
}

.background1-sm {
    background: linear-gradient(to left, #e0efff3c, #3476b8)
}

.background2 {
    background: linear-gradient(to top, #3577B8, #3476b8)
}

.background3 {
    background: linear-gradient(to bottom, #e0efff3c, #3476b8)
}

.expand-enter-active {
    animation: expand 0.5s ease forwards;
}

.expand-leave-active {
    animation: none;
}

@keyframes expand {
    from {
        opacity: 0;
        width: 0%;
        height: 100%;
    }

    to {
        opacity: 1;
        width: 100%;
        height: 100%;
    }
}
</style>