<template>
    <div v-if="loaded">
        <CurrentSituation :data="intro" />
        <CarrouselIO :titulo1="carrousel.paragraph.header" :titulo2="carrousel.paragraph.blueHeader"
            :texto="carrousel.paragraph.content[0].text" :cards="carrousel.busCards" :items="carrousel.dynCards" />
        <ProductsIO :nome="produtos.headers.header" :nome1="produtos.headers.blueHeader" :produtos="produtos.data">
        </ProductsIO>
        <OurProjects :nome="projetos.headers.blueHeader" :nome1="projetos.headers.header" :items="projetos.data"
            :expand="true" />
        <ClientsIO :nome="clientes.headers.header" :nome1="clientes.headers.blueHeader" :clientes="clientes.data" />
    </div>
    <TheSpinner v-else></TheSpinner>
</template>

<script setup>
import TheSpinner from '@/components/layout/TheSpinner.vue';
import CarrouselIO from '@/components/ui/industria/CarrouselIO.vue';
import ClientsIO from '@/components/ui/industria/ClientsIO.vue';
import CurrentSituation from '@/components/ui/industria/CurrentSituation.vue';
import ProductsIO from '@/components/ui/OurProducts.vue';
import OurProjects from '@/components/ui/OurProjects.vue';

import useLoad from '@/hooks/load';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();

const page = computed(() => {
    if (route.params.id === 'empresas-e-industrias-4.0') {
        return 2;
    }
    else if (route.params.id === 'smart-cities') {
        return 4;
    }
    else return 0
})

const [, , loadMultiPage, timerMultiPage] = useLoad('industries/getCounter', 'industries/getData', page, page)

loadMultiPage().then(timerMultiPage)

const loaded = computed(() => {
    return store.getters['industries/getCounter'][page.value]
})

watch(() => (page.value), (pageNew, pageOld) => {
    if (pageNew != pageOld) {
        loadMultiPage().then(timerMultiPage)
    }
})
const intro = computed(() => {
    const data = store.getters['industries/getIntro'][page.value]
    if (data) return data.data
    else return ''
});
const carrousel = computed(() => {
    const data = store.getters['industries/getCarrousel'][page.value]
    if (data) return data.data
    else return ''
});
const produtos = computed(() => {
    return store.getters['industries/getProdutos'][page.value]
});
const projetos = computed(() => {
    return store.getters['industries/getProjetos'][page.value]
});
const clientes = computed(() => {
    return store.getters['industries/getClientes'][page.value]
});
</script>

<style lang="scss" scoped></style>