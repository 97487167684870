<template>
    <div class="mt-12 mb-12 mt-xl-16 mb-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <div>
            <v-row no-gutters>
                <v-col cols="12" class="d-md-none mt-5">
                    <div class="d-flex flex-column w-100 pt-2">
                        <div class="main-title font-weight-bold">
                            {{ props.titulo1 }} <span class="text-primary"> {{ props.titulo2 }}</span>
                        </div>
                        <div class="mt-2 pl-1 mb-5 normal-text">
                            {{ props.texto }}
                        </div>
                    </div>
                </v-col>
                <v-col v-if="$route.params.id === 'empresas-e-industrias-4.0'" cols="12" md="6"
                    class="d-flex align-start pr-md-6" id="coluna1">
                    <v-row class="d-flex">
                        <v-col cols="12" class="d-none d-md-flex flex-column pt-0 w-100  mb-xl-4">
                            <div class="main-title font-weight-bold">
                                {{ props.titulo1 }} <span class="text-primary"> {{ props.titulo2 }}</span>
                            </div>
                            <div class="mt-2 mt-xl-5 mb-xl-2 normal-text">
                                {{ props.texto }}
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" v-for="(card, index) in props.cards" :key="index"
                            class="d-flex justify-center mt-0 pt-1">
                            <div class="d-flex flex-column justify-center text-center align-center rounded-xl pa-10 pa-md-5 w-100"
                                :class=[size]>
                                <v-icon :icon="'mdi-' + card.icon"
                                    :size="vuetify.display.smAndDown.value ? '8vh' : '5.4vw'" :color="colors[index]"
                                    class="pt-1" />
                                <span class="description-text font-weight-bold">
                                    {{ card.title }}
                                </span>
                                <span class="small-text">
                                    {{ card.description }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6"
                    :class="[route.params.id === 'smart-cities' ? ' mb-10 mt-md-0 pr-md-6' : 'mt-10 mt-md-0 pl-md-6']">
                    <v-carousel :show-arrows="false" hide-delimiters class="rounded-xl" cycle interval="8000"
                        :height="vuetify.display.smAndDown.value ? '50vh' : height + 'px'" touch direction="vertical">
                        <v-carousel-item v-for="(item, i) in props.items" :key="i"
                            :src="'https://site-api.iotechpis.com' + item.image.url" cover
                            gradient="#00000030, #000000c7">
                            <v-icon :icon="'mdi-' + item.accessPoint"
                                :size="vuetify.display.mdAndDown.value ? '6vh' : '6vw'" color="white"
                                class="position-absolute top-0 right-0 ma-6 ma-xl-4 opacity-70 mt-3 mt-xl-1">
                            </v-icon>
                            <div class="position-absolute top-0 left-0 ma-6 text-end">
                                <v-btn :to="item.to" color="white" rounded
                                    :size="vuetify.display.lgAndUp.value ? 'x-large' : ''"
                                    class=" pa-2 px-3 px-md-5 justify-end opacity-70"
                                    append-icon="mdi-arrow-top-right-thick">
                                    <span class="opacity-100 small-text font-weight-medium">
                                        {{ item.button.title }}
                                    </span>
                                </v-btn>
                            </div>
                            <v-container
                                class="d-md-flex flex-column ga-2 position-absolute bottom-0 text-white pa-7 description-text">
                                <div class="font-weight-bold w-100"
                                    :class="[vuetify.display.xs.value ? 'normal-text' : 'main-title']">
                                    {{ item.title }}
                                </div>
                                <div :class="[display]">
                                    {{ item.description }}
                                </div>
                            </v-container>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col v-if="$route.params.id == 'smart-cities'" cols="12" md="6" class="d-flex align-start pl-md-6"
                    id="coluna2">
                    <v-row class="d-flex">
                        <v-col cols="12" class="d-none d-md-flex flex-column pt-0 w-100  mb-xl-4">
                            <div class="main-title font-weight-bold">
                                {{ props.titulo1 }} <span class="text-primary"> {{ props.titulo2 }}</span>
                            </div>
                            <div class="mt-2 mt-xl-5 mb-xl-2 normal-text">
                                {{ props.texto }}
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" v-for="(card, index) in props.cards" :key="index"
                            class="d-flex justify-center mt-0 pt-1">
                            <div class="d-flex flex-column justify-center text-center align-center rounded-xl pa-10 pa-md-5 w-100"
                                :class=[size]>
                                <v-icon :icon="'mdi-' + card.icon"
                                    :size="vuetify.display.smAndDown.value ? '8vh' : '5.4vw'" :color="colors[index]"
                                    class="pt-1" />
                                <span class="description-text font-weight-bold">
                                    {{ card.title }}
                                </span>
                                <span class="small-text">
                                    {{ card.description }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const props = defineProps(['titulo1', 'titulo2', 'texto', 'cards', 'items'])

const colors = ['blue', 'green', 'red', 'yellow']

const size = computed(() => {
    if (vuetify.display.md.value) {
        return 'border';
    }
    else if (vuetify.display.lg.value) {
        return 'border-lg';
    }
    else if (vuetify.display.xlAndUp.value) {
        return 'border-xl';
    }
    else {
        return 'border-sm';
    }
})

const display = computed(() => {
    if (vuetify.display.width.value < 360) {
        return 'd-none'
    }
    else return ''
})


onMounted(getCol)
const height = ref()
function getCol() {
    if (route.params.id === 'empresas-e-industrias-4.0') {
        const col1 = document.getElementById('coluna1');
        if (col1) { height.value = col1.scrollHeight - 11; }
    }
    else {
        const col1 = document.getElementById('coluna2');
        if (col1) { height.value = col1.scrollHeight - 11; }
    }
}

watch(() => route.params.id, () => {
    setTimeout(() => {
        getCol()
    }, 100)
})


watch(() => (vuetify.display.width.value || vuetify.display.height.value), () => {
    setTimeout(() => {
        getCol()
    }, 100)
})

</script>

<style scoped>
.border {
    border: 2px solid #2D5FA9 !important;
    height: 13.90vw;
}

.border-lg {
    border: 2px solid #2D5FA9 !important;
    height: 14.15vw;
    /* 18.55 */
}

.border-xl {
    border: 2px solid #2D5FA9 !important;
    height: 14.80vw;
    /*fora de rácio dos anteriores */
}

.border-sm {
    border: 2px solid #2D5FA9 !important;
    height: 28vh;
    /*fora de rácio dos anteriores */
}
</style>