<template>
    <div class="mt-12 mb-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <div class="d-md-flex main-title mt-3 font-weight-bold">
            <span class="d-flex d-sm-inline">
                {{ props.nome + '&nbsp;' }}
            </span>
            <span class="text-primary">
                {{ props.nome1 }}
            </span>
        </div>
        <div class="w-100 normal-text font mt-5">
            {{ props.text }}
        </div>
        <v-row no-gutters class="mt-10 d-flex mx-n4">
            <v-col cols="12" sm="6" md="3" v-for="(offer, index) in offers" :key="index">
                <v-card class="d-grid elevation-0 rounded-xl pa-8  pa-xl-6 mx-4 my-4 my-md-0 gradient"
                    color="lightGrey">
                    <v-icon :icon="'mdi-' + offer.icon" class="mb-2 text-h2 ml-n1" :color="offer.color"></v-icon>
                    <div class="normal-text font-weight-bold">
                        {{ offer.header }}
                    </div>
                    <div class=" mt-2">
                        <v-list class="bg-transparent ma-0">
                            <v-list-item v-for="(item, index) in offer.attributes" :key="index" class="ma-0 pl-0">
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-check-circle" :color="offer.color" class="mr-n5"></v-icon>
                                </template>
                                <v-list-item-title v-text="item.text" class="description-text"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['nome', 'nome1', 'text', 'offers'])
</script>

<style scoped>
.gradient {
    background: linear-gradient(to bottom left, #00000004, #00000003, #00000002, #00000000);
}
</style>