<template>
  <v-navigation-drawer v-model="drawer" location="right" width="2000" close-delay="2000" temporary id="#drawer">
    <v-container fluid class="d-flex flex-column text-text ga-3 mt-sm-16 mt-10 scrollable">
      <div v-for="(item, i) in items" :key="item" class="text-left">
        <v-expansion-panels v-if="item.options" v-model="isSelected[i]" class="elevation-0 ml-0 px-2 px-md-16">
          <v-expansion-panel :key="item.nome" elevation=0>
            <v-expansion-panel-title class="small-text text-text">{{ item.nome }}</v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list>
                <v-list-item v-for="option in item.options" :to="item.to + option.to" :key="option.nome"
                  @click="drawerSwitch" class="mini-text text-text">
                  {{ option.nome }}
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item v-else :to="item.to" class="tex-text small-text mx-4 mx-md-16 px-md-6 py-3 item"
          @click="drawerSwitch">
          {{ item.nome }}
        </v-list-item>
        <v-divider thickness="1.6" color="info" class="mx-7 mx-md-16"></v-divider>
      </div>
      <v-row class="d-flex pt-7 mx-7 mx-md-16 px-md-6 pb-3">
        <v-btn prepend-icon="mdi-email" class="rounded-xl border-sm  border-opacity-100 small-text" color="transparent"
          variant="flat" href="mailto:info@iotech.pt">
          Email</v-btn>
        <v-spacer></v-spacer>
        <v-btn prepend-icon="mdi-phone" class="rounded-xl small-text" variant="flat" color="#6D9DF5"
          href="tel:+351302002958">Ligar</v-btn>
      </v-row>
      <v-row class=" mx-7 mx-md-16 px-md-6 mb-4">
        <v-btn id="languages" block variant="outlined" color="black" class="small-text" rounded="xl"
          @click="setVisibleLanguage">
          <v-img :src="VisibleLanguage.icon" width="30%" class="mr-1" rounded="lg"></v-img>
          {{ VisibleLanguage.nome }}
        </v-btn>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script setup>
import vuetify from "@/plugins/vuetify";
import { computed, defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute()

const props = defineProps(["drawer"]);
const emits = defineEmits(["drawerSwitch"]);

onMounted(routeChange)

//////////////////////////////language
const selected = computed(() => {
  return store.getters.getSelectedLanguage;
})
const VisibleLanguage = ref()
VisibleLanguage.value = selected.value;

watch(() => selected.value, () => {
  VisibleLanguage.value = selected.value;
})


function setVisibleLanguage() {
  index.value++
  VisibleLanguage.value = languages[index.value]
}

const languages = store.getters.getAvailableLanguages
const firstIndex = computed(() => {
  languages.forEach((language) => {
    if (language.lang === selected.value.lang) {
      return languages.indexOf(language)
    }
  })
  return 0
})
const index = ref(0)
index.value = firstIndex.value

watch(() => index.value, () => {
  if (index.value >= languages.length) {
    index.value = 0
    VisibleLanguage.value = languages[0]
  }
})
////////////////////////////////
const isSelected = ref([])
const items = computed(() => {
  return store.getters.getItems;
});



const drawer = computed(() => {
  return props.drawer;
});

function drawerSwitch() {
  emits("drawerSwitch");
}


//manter painel expanded segundo rota
function routeChange() {
  if (vuetify.display.mobile.value) {
    isSelected.value = []
    items.value.forEach((item) => {
      if (item.options && route.params.id) {
        item.options.forEach((option) => {
          if (option.to === '/' + route.params.id)
            isSelected.value[items.value.indexOf(item)] = 0

        })
      }
      else if (item.options) {
        item.options.forEach((option) => {
          if (option.to === '/' + route.path.split('/')[2])
            isSelected.value[items.value.indexOf(item)] = 0
        }
        )
      }
    })
  }
}
//


watch(() => drawer.value, () => {
  if (!drawer.value && selected.value != VisibleLanguage.value) {
    store.dispatch('setSelectedLanguage', languages[index.value])
    localStorage.setItem('preferences', JSON.stringify(languages[index.value]))
    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
    document.body.removeEventListener('touchmove', preventScroll);
  }
  else if (drawer.value) {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
    document.body.addEventListener('touchmove', preventScroll, { passive: false });
  }
  else if (!drawer.value) {
    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
    document.body.removeEventListener('touchmove', preventScroll);
  }
})

watch(() => (route.path), () => {
  routeChange()
})


function preventScroll(event) {
  const scrollable = document.querySelector('.scrollable');
  if (scrollable && scrollable.contains(event.target)) {
    return;
  }
  event.preventDefault();
}
</script>

<style scoped>
.v-btn {
  transition: color 0.5s;
}

.v-btn--active {
  color: #2D5FA9 !important;
}

.v-list-item--active {
  color: #2D5FA9 !important;
}

.item {
  opacity: 1;
  -family: 'Montserrat', sans-serif;
}

.left {
  top: 100;
  left: 0%;
  z-index: 100000;
}

.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
}
</style>
