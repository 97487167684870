<template>
    <div class="mt-12 mb-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <v-row no-gutters>
            <v-col cols="12" class="d-flex align-center ml-0 pl-0">
                <div class="w-100 text-no-wrap mr-4 mr-md-8 font-weight-bold main-title">
                    <div :class="vuetify.display.xs.value ? 'd-flex flex-column' : ''">
                        <span>
                            {{ props.nome + '&nbsp;' }}
                        </span>
                        <span class="text-primary">{{ props.nome1 }}</span>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters justify="space-between" class="mx-n3 mt-3">
            <v-col cols="12" md="6" v-for="(produto, index) in props.produtos" :key="index" class="pa-3"
                :class="index <= 1 ? 'pt-2' : ''">
                <v-container fluid class="d-flex ga-6 rounded-xl align-center pa-5"
                    :class="['bg-' + produto.color, vuetify.display.width.value > 800 ? '' : 'flex-column', index % 2 == 0 ? 'background' : 'background1']">
                    <v-card class="rounded-xl opacity-80 elevation-0">
                        <v-img :src="'https://site-api.iotechpis.com' + produto.image.url" width="110"
                            class="rounded-xl opacity-100" aspect-ratio="
                                1"></v-img>
                    </v-card>
                    <div class="d-flex justify-start ga-3 w-100"
                        :class="[vuetify.display.width.value > 800 ? '' : 'flex-column']">
                        <div class="d-flex flex-column w-md-50 w-xl-75"
                            :class="[vuetify.display.width.value > 800 ? 'w-50' : '']">
                            <span class="normal-text font-weight-bold">{{ produto.name }}</span>
                            <span class="description-text">{{ produto.slogan }}</span>
                        </div>
                        <div class="w-100 w-md-50 d-flex justify-center justify-sm-end">
                            <v-btn color="white" append-icon="mdi-arrow-top-right-thick" :href="produto.button.url"
                                target="_blank" class="d-flex py-6 rounded-xl align-center opacity-80">
                                <span class="small-text">
                                    {{ produto.button.title }}
                                </span>
                            </v-btn>
                        </div>
                    </div>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { defineProps } from 'vue';

const props = defineProps(['nome', 'nome1', 'produtos'])


</script>

<style scoped>
.background {
    background: linear-gradient(to right, #ffffff25, transparent)
}

.background1 {
    background: linear-gradient(to left, #ffffff25, transparent)
}
</style>