const aboutUs = {
    namespaced: true,
    state: {
        introduction: {},
        processo: {},
        equipa: {},
        counter: false,
    },
    getters: {
        getCounter(state) {
            return state.counter;
        },
        getIntroduction(state) {
            return state.introduction;
        },
        getProcesso(state) {
            return state.processo;
        },
        getEquipa(state) {
            return state.equipa;
        },
    },
    mutations: {
        setCounter(state, payload) {
            state.counter = payload;
        },
        setIntroduction(state, payload) {
            state.introduction = payload;
        },
        setProcesso(state, payload) {
            state.processo = payload;
        },
        setEquipa(state, payload) {
            state.equipa = payload;
        },
    },
    actions: {
        async getData(context) {
            const data = await fetch(process.env.VUE_APP_ABOUTUS_PAGE);
            if (data.ok) {
                const json = await data.json();
                console.log(json.aboutPage);

                context.commit("setIntroduction", json.aboutPage.intro);
                context.commit("setProcesso", {
                    header: json.aboutPage.processSection.header,
                    header_blue: json.aboutPage.processSection.blueHeader,
                    description: {
                        ...json.aboutPage.processSection.content[0],
                    },
                    data: json.aboutPage.process,
                });
                context.commit("setEquipa", {
                    header: json.aboutPage.workSection.header,
                    header_blue: json.aboutPage.workSection.blueHeader,
                    description: { ...json.aboutPage.workSection.content[0] },
                    data: json.aboutPage.workers,
                });

                context.commit("setCounter", true);
            } else {
                alert(data.status);
            }
        },
    },
    modules: {},
};

export default aboutUs;
