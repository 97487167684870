<template>
    <div v-if="loaded">
        <BiographyIO :intro="intro"></BiographyIO>
        <OurProcess :nome="processo.header" :nome1="processo.header_blue" :texto="processo.description"
            :passos="processo.data"></OurProcess>
        <OurProjects :nome="equipa.header_blue" :nome1="equipa.header" :texto="equipa.description.text"
            :items="equipa.data" bg="bass" :expand="false" justify="justify-center" />
    </div>
    <TheSpinner v-else></TheSpinner>
</template>

<script setup>
import TheSpinner from '@/components/layout/TheSpinner.vue';
import OurProjects from '@/components/ui/OurProjects.vue';
import BiographyIO from '@/components/ui/sobrenos/BiographyIO.vue';
import OurProcess from '@/components/ui/sobrenos/OurProcess.vue';

import useLoad from '@/hooks/load';
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const [load, timer] = useLoad('aboutUs/getCounter', 'aboutUs/getData');

load().then(timer)

const loaded = computed(() => {
    return store.getters['aboutUs/getCounter']
})

watch(() => loaded.value, () => {
    if (!loaded.value) {
        load().then(timer)
    }
})

const intro = computed(() => {
    return store.getters['aboutUs/getIntroduction']
})
const processo = computed(() => {
    return store.getters['aboutUs/getProcesso']
})
const equipa = computed(() => {
    return store.getters['aboutUs/getEquipa']
})
</script>
<style lang="scss" scoped></style>