<template>
    <div class="bg-blueWhite back" :style="[backgroundImg]">
        <div class="circle"></div>
        <div class="d-flex flex-column py-10 py-md-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
            <v-row no-gutters>
                <v-col cols="12" sm="10" xl="8" class="d-flex flex-column ga-4 my-4">
                    <div class="mb-5 w-xl-75">
                        <span class="main-title font-weight-bold">
                            <span v-for="item in final" :key="item"
                                :class="palavras.includes(item) ? 'text-primary' : 'text-text'">
                                {{ item }}
                            </span>
                        </span>
                    </div>
                    <div class="w-75">
                        <v-btn color="primary" rounded :to="props.button.isExternal ? '' : '/industries'"
                            :href="props.button.isExternal ? button.url : ''"
                            :target="props.button.isExternal ? '_blank' : ''" class="description-text">
                            {{ button.title }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { computed, defineProps, ref } from 'vue';

const props = defineProps(['texto', 'palavras', 'url', 'button'])

const palavras = ref([])
props.palavras.forEach(element => {
    palavras.value.push(element.text)
});
const final = ref([])

function highlight() {
    const regex = new RegExp(`(${palavras.value.join('|')})`, 'gi');
    final.value = props.texto.split(regex);
}
highlight()


const backgroundImg = computed(() => {
    return {
        backgroundImage: `url(${'https://site-api.iotechpis.com' + props.url})`,
        backgroundPosition: `${vuetify.display.mdAndUp.value ? 'right' : ''}`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${vuetify.display.mdAndUp.value ? 'contain' : 'cover'}`,
    };
});
</script>

<style scoped>
.back:before {
    position: absolute;
    bottom: -80%;
    left: -15%;
    width: 100%;
    height: 120%;
    background: radial-gradient(circle, #e0bf5e9c 0%, rgba(255, 255, 255, 0) 20%);
    opacity: 0.50;
    content: '';
    z-index: -1;
}

.back .circle {
    position: absolute;
    bottom: -100%;
    left: -50%;
    width: 140%;
    height: 160%;
    background: radial-gradient(circle, #e0bf5e9c 0%, rgba(255, 255, 255, 0) 20%);
    opacity: 0.55;
    content: '';
    z-index: -1;
}

.back:after {
    position: absolute;
    bottom: -60%;
    left: -25%;
    width: 60%;
    height: 120%;
    background: radial-gradient(circle, #e0bf5e9c 0%, rgba(255, 255, 255, 0) 30%);
    opacity: 0.50;
    content: '';
    z-index: -1;
}

.back {
    position: relative;
    overflow: hidden;
    z-index: 0;
}
</style>