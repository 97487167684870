<template>
    <div :class="vuetify.display.lgAndDown.value ? vuetify.display.mobile.value ? 'height-xs' : 'height' : 'height-xl'"
        class="d-flex align-center">
        <div class="w-100 d-flex justify-center align-center">
            <v-img src="../../assets/img/logo/color-logo.png" class="heartbeat" rounded="0" height="10vh">
            </v-img>
        </div>
        <div class="width text-center mt-8 text-primary description-text">
            <span class="typing-animation">const page= await axios.get('{{ '/' + $route.name }}')</span>
        </div>
    </div>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { onMounted } from 'vue';

onMounted(() => {
    const element = document.querySelector('.typing-animation');
    element.addEventListener('animationend', () => {
        setTimeout(() => {
            element.style.animation = 'none';
            element.offsetHeight;
            element.style.animation = null;
        }, 2000)
    });
})
</script>

<style scoped>
.height-xs {
    height: 90vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


.height {
    height: 86vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.height-xl {
    height: 90vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.width {
    width: min-content;
}

.typing-animation {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    border-right: 3px solid;
    animation: typing 4.5s steps(35, end), blink-caret 0.65s step-end infinite;
    width: 100%;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: black;
    }
}
</style>