<template>
    <div class="mt-12 mb-12 mt-xl-16 px-8 px-md-16 mx-4 mx-md-10 mx-xl-16">
        <BaseIdentifier v-if="vuetify.display.mobile.value" class="mb-5" :nome="props.header" :color="props.color"
            :icon="props.icon">
        </BaseIdentifier>
        <div class="w-100 pt-8">
            <v-row no-gutters justify="space-between" class="mb-sm-n8 mb-md-n16"
                :class="[vuetify.display.mobile.value ? 'd-inline' : 'd-flex']">
                <div v-for="(card, index) in cards" :key="index" class="d-flex justify-center">
                    <v-card v-if="!(vuetify.display.mobile.value && index >= 1)"
                        :height="vuetify.display.mobile.value ? '30vh' : '20vw'"
                        :width="vuetify.display.mobile.value ? '25vh' : '15vw'" class=" elevation-0" rounded="xl"
                        :class="[+ vuetify.display.mobile.value ? '' : 'style' + index]">
                        <v-container v-if="!card.image" class="d-flex h-100 flex-column pa-6 description-text ">
                            <span>
                                {{ card.header }}
                            </span>
                            <v-spacer></v-spacer>
                            <span>
                                {{ card.description }}
                            </span>
                        </v-container>
                        <v-img v-else :src="'https://site-api.iotechpis.com' + card.image.url" cover>
                        </v-img>
                    </v-card>
                </div>
            </v-row>
            <v-row no-gutters>
                <div class="mt-xl-n16 w-100">
                    <v-col cols="12"
                        class="d-flex flex-column ga-3 align-center justify-center mt-10 mt-md-n16 pt-md-n16">
                        <BaseIdentifier v-if="!vuetify.display.mobile.value" :nome="$route.name" color="purple"
                            :icon="props.icon">
                        </BaseIdentifier>
                        <div class="intro-title mt-3 text-center font-weight-bold text-wrap">
                            <span>
                                {{ props.nome + '&nbsp;' }}
                            </span>
                            <span class="text-primary">
                                {{ props.nome1 }}
                            </span>
                        </div>
                        <div class="w-sm-75 w-md-50 text-center normal-text font-weight-bold">
                            {{ props.texto }}
                        </div>
                    </v-col>
                </div>
            </v-row>
        </div>
    </div>
</template>



<script setup>
import vuetify from '@/plugins/vuetify';
import { defineProps } from 'vue';
import BaseIdentifier from '../BaseIdentifier.vue';

const props = defineProps(['nome', 'nome1', 'texto', 'cards', 'header', 'color', 'icon'])

// const items = ref([
//     { subtext: 'path/to/image4.jpg', text: 'Lorem Ipsum is simply dummy text', url: 'https://img.freepik.com/fotos-gratis/vista-da-sala-de-aula-da-escola_23-2151031912.jpg?t=st=1723471531~exp=1723475131~hmac=6873d36805d763aa540d8795601f5925a47da010739dbb54d417b3cec8b0e0f2&w=740' },
//     { subtext: 'path/to/image1.jpg', text: 'Lorem Ipsum is simply dummy text' },
//     { subtext: 'path/to/image2.jpg', text: 'Lorem Ipsum is simply dummy text', url: 'https://img.freepik.com/fotos-gratis/vista-da-sala-de-aula-da-escola_23-2151031912.jpg?t=st=1723471531~exp=1723475131~hmac=6873d36805d763aa540d8795601f5925a47da010739dbb54d417b3cec8b0e0f2&w=740' },
//     { subtext: 'path/to/image3.jpg', text: 'Lorem Ipsum is simply dummy text' },
//     { subtext: 'path/to/image4.jpg', text: 'Lorem Ipsum is simply dummy text', url: 'https://img.freepik.com/fotos-gratis/vista-da-sala-de-aula-da-escola_23-2151031912.jpg?t=st=1723471531~exp=1723475131~hmac=6873d36805d763aa540d8795601f5925a47da010739dbb54d417b3cec8b0e0f2&w=740' },
//     { subtext: 'path/to/image5.jpg', text: 'Lorem Ipsum is simply dummy text' },
//     { subtext: 'path/to/image5.jpg', text: 'Lorem Ipsum is simply dummy text' },
// ],)
</script>

<style scoped>
.height {
    height: 30vw;
}

.style0 {
    left: -20%;
    bottom: -50%;
    transform: rotate(-35deg);
}

.style1 {
    left: -20%;
    bottom: -2%;
    transform: rotate(-20deg);
}

.style2 {
    bottom: 19%;
}

.style3 {
    right: -20%;
    top: 2%;
    transform: rotate(20deg);
}


.style4 {
    right: -20%;
    bottom: -50%;
    transform: rotate(35deg);
}

.style5 {
    left: -125%;
    bottom: -20%;
    transform: rotate(-50deg);
}

.style6 {
    right: -125%;
    bottom: -20%;
    transform: rotate(50deg);
}
</style>
