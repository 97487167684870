import { createStore } from "vuex";
import aboutUs from "./aboutus";
import careers from "./careers";
import industries from "./industries";
import news from "./news";
import products from "./products";
import solutions from "./solutions";

export default createStore({
    modules: {
        industries,
        solutions,
        aboutUs,
        careers,
        products,
        news,
    },
    state: {
        drawer: false,
        count: 0,
        items: [
            {
                id: 1,
                nome: "Sobre Nós",
                to: "/aboutus",
                options: [
                    {
                        nome: "Quem nós somos",
                        to: "/quem-nos-somos",
                    },
                    {
                        nome: "A nossa identidade",
                        to: "/a-nossa-identidade",
                    },
                    {
                        nome: "Reconhecimento",
                        to: "/reconhecimento",
                    },
                ],
            },
            {
                id: 2,
                nome: "Indústrias",
                to: "/industries",
                options: [
                    {
                        nome: "Empresas e Indústrias 4.0",
                        to: "/empresas-e-industrias-4.0",
                    },
                    {
                        nome: "Smart Cities",
                        to: "/smart-cities",
                    },
                ],
            },
            {
                id: 3,
                nome: "Soluções",
                to: "/solutions",
                options: [
                    {
                        nome: "Ciências de Dados e Inteligência Artificial",
                        to: "/ciencias-de-dados-e-inteligencia-artificial",
                    },
                    {
                        nome: "Interoperabilidade e conectividade",
                        to: "/interoperabilidade-e-conectividade",
                    },
                    {
                        nome: "Transição digital",
                        to: "/transicao-digital",
                    },
                    {
                        nome: "Gamificação",
                        to: "/gamificacao",
                    },
                ],
            },
            {
                id: 4,
                nome: "Produtos",
                to: "/products",
            },
            {
                id: 5,
                nome: "Carreiras",
                to: "/careers",
                options: [
                    {
                        nome: "Trabalhar na IOTech",
                        to: "/work-at-iotech",
                    },
                    {
                        nome: "A nossa Academia",
                        to: "/ioacademy",
                    },
                ],
            },
            {
                id: 6,
                nome: "Notícias",
                to: "/news",
            },
        ],
        selectedLanguage: {},
        availableLanguages: [
            {
                nome: "Português",
                lang: "pt",
                icon: "https://flagcdn.com/h24/pt.png",
            },
            {
                nome: "English",
                lang: "en",
                icon: "https://flagcdn.com/h24/us.png",
            },
            {
                nome: "Française",
                lang: "fr",
                icon: "https://flagcdn.com/h24/fr.png",
            },
            {
                nome: "Español",
                lang: "es",
                icon: "https://flagcdn.com/h24/es.png",
            },
        ],
    },
    getters: {
        getDrawer(state) {
            return state.drawer;
        },
        getCount(state) {
            return state.count;
        },
        getItems(state) {
            return state.items;
        },
        getSelectedLanguage(state) {
            return state.selectedLanguage;
        },
        getAvailableLanguages(state) {
            return state.availableLanguages;
        },
    },
    mutations: {
        setDrawer(state, payload) {
            state.drawer = payload;
        },
        setSelectedLanguage(state, payload) {
            console.log(state);
            state.aboutUs.counter = false;
            state.careers.counter = [];
            state.industries.counter = [];
            state.solutions.counter = [];
            state.selectedLanguage = payload;
        },
        setAvailableLanguages(state, payload) {
            state.availableLanguages = payload;
        },
    },
    actions: {
        setDrawer(context, payload) {
            context.commit("setDrawer", payload);
        },
        setSelectedLanguage(context, payload) {
            context.commit("setSelectedLanguage", payload);
        },
    },
});
