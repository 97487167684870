<template>
    <div class="d-flex rounded-xl pa-3 px-4 white mini-text" :class="['bg-' + props.color]">
        <span v-if="props.hashtag">#</span>
        <slot></slot>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['color', 'hashtag'])
</script>

<style scoped>
.white {
    color: white !important;
}
</style>