<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
        :width="vuetify.display.mdAndUp.value ? props.width : props.width / 2"
        :height="vuetify.display.mdAndUp.value ? props.height : props.height / 2">
        <path fill="#00a8c9"
            d="M224 0C348.8 0 448 35.2 448 80l0 16 0 320c0 17.7-14.3 32-32 32l0 32c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32l0-32-192 0 0 32c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32l0-32c-17.7 0-32-14.3-32-32L0 96 0 80C0 35.2 99.2 0 224 0zM64 128l0 128c0 17.7 14.3 32 32 32l256 0c17.7 0 32-14.3 32-32l0-128c0-17.7-14.3-32-32-32L96 96c-17.7 0-32 14.3-32 32zM80 400a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm288 0a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
    </svg>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { defineProps } from 'vue';
const props = defineProps(['width', 'height']);
</script>